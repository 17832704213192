import React from 'react'
import ScreenWrapper from '../components/ScreenWrapper'
import { View, ContentButton, Card } from '../styles'
import { connect, ConnectedProps } from 'react-redux'
import { NavigationPop, ActionType, AppAction } from '../actions'
import { Table } from '../components/Table'
import { LS } from '../loc/loc'
import { Dispatch } from 'redux'

const connector = connect(undefined, (dispatch: Dispatch<AppAction>) => ({
    dispatch,
}))

export type ListSelectorScreenProps = {
    columnNames: string[],
    columnWidths?: number[],
    items: string[][],
    multiSelection: boolean,
    onConfirm: (selectedIndices: number[]) => void,
    allowSelectNothing?: boolean,
    initialSelectedIndices?: number[],
}

export type AllProps = ListSelectorScreenProps & ConnectedProps<typeof connector>

class ListSelectorScreenComponent extends React.PureComponent<AllProps, {selectedIndices: number[]}> {
    constructor(props: AllProps) {
        super(props)

        this.state = {
            selectedIndices: props.initialSelectedIndices ?? []
        }
    }

    _onItemAddPress(index: number): void {
        if (!this.props.multiSelection && !this.props.allowSelectNothing) {
            this.props.dispatch({type: ActionType.NAVIGATION_POP})
            this.props.onConfirm([index])
        } else {
            this.setState({
                selectedIndices: this.props.multiSelection ?
                    this.state.selectedIndices.concat([index]) :
                    [index]
            })
        }
    }

    _onItemRemovePress(index: number): void {
        this.setState({
            selectedIndices: this.state.selectedIndices.filter(i => i !== index)
        })
    }

    _onConfirmPress(): void {
        const popAction: NavigationPop = {type: ActionType.NAVIGATION_POP}
        this.props.dispatch(popAction)
        this.props.onConfirm(this.state.selectedIndices)
    }

    _onCancelPress(): void {
        const popAction: NavigationPop = {type: ActionType.NAVIGATION_POP}
        this.props.dispatch(popAction)
    }

    render() {
        const {columnNames, items, columnWidths, allowSelectNothing, multiSelection} = this.props

        const selectOneAndOnlyOne = !multiSelection && !allowSelectNothing

        const {selectedIndices} = this.state
        const nonSelectedIndices = items.map((_, idx) => idx).filter(idx => selectedIndices.indexOf(idx) < 0)

        const headerButtons =
            <React.Fragment>
                {!selectOneAndOnlyOne && <ContentButton label={LS('ok')} onPress={(allowSelectNothing || selectedIndices.length) > 0 ? () => this._onConfirmPress() : undefined} />}
                <ContentButton label={LS('cancel')} onPress={() => this._onCancelPress()} />
            </React.Fragment>
        
        return (
            <ScreenWrapper headerItem={headerButtons}>
                <View style={{flexDirection: 'column'}}>
                    <View style={{maxHeight: 360}}>
                        <Card containerStyle={{flexGrow: 0, overflow: 'hidden'}}>
                            <Table fillContainer={'both'} showFilters={[true, true]} columnNames={columnNames} columnWidths={columnWidths} items={items.filter((_, index) => nonSelectedIndices.indexOf(index) >= 0)} onPressItem={index => this._onItemAddPress(nonSelectedIndices[index])} />
                        </Card>
                    </View>
                    {!selectOneAndOnlyOne && <View>
                        <Card containerStyle={{flexGrow: 0, overflow: 'hidden'}}>
                            <Table fillContainer={'both'} columnNames={[LS('selectedItems'), ""]} columnWidths={columnWidths} items={items.filter((_, index) => selectedIndices.indexOf(index) >= 0)} onPressItem={index => this._onItemRemovePress(selectedIndices[index])} />
                        </Card>
                    </View>}
                </View>
            </ScreenWrapper>
        )
    }
}

export default connector(ListSelectorScreenComponent)