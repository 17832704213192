import React, { Dispatch, ReactElement, ReactNode } from 'react'
import {connect, ConnectedProps} from 'react-redux'
import {RootState, ScreenStackItem} from '../state'
import { logoSize, pageWidth, headerRowHeight, menuHeaderColor, headerFontSize, pageBgColor, View, BaseText, palette, MenuButton, ContentImage, standardHalfPadding, standardHalfMargin, pageBoxShadow, ContentText, standardHalfMarginHor } from '../styles'
import ScreenContent from './ScreenContent'
import { routeDataFromId, ScreenRouteId } from '../routing'
import { ActionType, AppAction } from '../actions'
import { LS } from '../loc/loc'
import { getVersionString, personHasPlatformRole } from '../utils'
import { PlatformRoleId, PublicDbInstanceData } from '../backendTypes'
import { getSelectedDbInstance, getUserPerson } from '../selectors'
import { SmallIcon } from './Icon'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import { screenHeaderLayout } from './ScreenHeader'
import { customSettings } from '../config'

const StyledReactTooltip = styled(ReactTooltip)`
    box-shadow: 1px 1px 8px #aaaaaa;
`

const connector = connect(
    (s: RootState) => ({
        commonData: s.commonData,
        navigation: s.navigation,
        userPerson: getUserPerson(s),
        selectedDbInstance: getSelectedDbInstance(s),
    }),
    (dispatch: Dispatch<AppAction>) => ({
        logout: () => dispatch({type: ActionType.LOGOUT}),
        navigateToScreen: (routeId: ScreenRouteId) => dispatch({type: ActionType.NAVIGATE_TO, stackItem: {routeId: routeId, props: null}}),
        dispatch: dispatch,
    })
)

type ScreenWrapperExternalProps = {
    headerItem?: React.ReactElement,
    children: React.ReactNode,
}

type ScreenWrapperAllProps = ScreenWrapperExternalProps & ConnectedProps<typeof connector>

export const screenWrapperLayout = (mainMenuElements: ReactElement[], dbInstanceButton: ReactElement | null, userName: string | null, privileged: boolean, logoutCallback: () => void, screenStack: ScreenStackItem[], children: ReactNode, headerItem?: ReactElement) => {
    const headerStatusTextStyle = {...standardHalfMargin, fontSize: headerFontSize, color: palette.white}
    return <View style={{flexDirection: 'column', width: pageWidth, alignSelf: 'center', flexGrow: 1, backgroundColor: pageBgColor, boxShadow: pageBoxShadow}}>
    <StyledReactTooltip backgroundColor={palette.textInputFocus} textColor={palette.borderFocus} effect='solid' />
    <View style={{flexDirection: 'row', height: headerRowHeight, backgroundColor: menuHeaderColor, alignItems: 'center', paddingLeft: standardHalfPadding.paddingLeft, paddingRight: standardHalfPadding.paddingRight}}>
        <ContentImage path={"krev-logo.png"} style={{width: logoSize, height: logoSize}} />
        {
            mainMenuElements
        }
        <View style={{justifyContent: 'flex-end', alignItems: 'center', flexDirection: 'row', flexGrow: 1}}>
            {dbInstanceButton}
            {userName !== null && <BaseText style={headerStatusTextStyle} string={userName} />}
            {privileged && <SmallIcon name='crown' style={{color: headerStatusTextStyle.color}} />}
            <MenuButton selected={false} label={LS('logout')} onPress={logoutCallback} />
        </View>
    </View>
    {screenHeaderLayout(screenStack, headerItem)}
    <ScreenContent>
        {children}
    </ScreenContent>
    <View style={{...standardHalfPadding, flexDirection: 'row-reverse'}}>
        <ContentText string={getVersionString()} style={{...standardHalfMargin, color: palette.borderDefault}} />
    </View>
    </View>
}

const ScreenWrapper: React.FunctionComponent<ScreenWrapperAllProps> = props => {
    const data = props.commonData
    if (data) {
        const userPerson = data.personsById.get(data.userData.personId)
        if (userPerson) {
            const privileged = personHasPlatformRole(userPerson, PlatformRoleId.Privileged)
            const mainMenuRouteIds = [
                    ScreenRouteId.Goals,
                    privileged ? ScreenRouteId.SharedCollections : null, 
                    (privileged && customSettings.showBackofficeForPrivileged) ? ScreenRouteId.Backoffice : null,
                ].filter(route => route !== null) as ScreenRouteId[]
            const userNameString = userPerson.firstName + " " + userPerson.lastName
            const onPressDbInstanceButton = () => {
                props.dispatch({type: ActionType.SET_SELECTED_DB_INSTANCE_ID, dbInstanceId: null})
            }
            return (
                screenWrapperLayout(
                    mainMenuRouteIds.map(id => <MenuButton label={routeDataFromId(id).title} onPress={() => props.navigateToScreen(id)} selected={props.navigation.screenStack.get(0)?.routeId === id} key={id} />),
                    <MenuButton selected={false} label={props.selectedDbInstance.title} onPress={onPressDbInstanceButton} />,
                    userNameString,
                    privileged,
                    props.logout,
                    props.navigation.screenStack.toArray(),
                    props.children,
                    props.headerItem
                )
            )
        }
    }
    throw new Error()
}

export default connector(ScreenWrapper)