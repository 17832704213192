import React, { CSSProperties } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { RootState } from '../state'
import { AppAction, ActionType } from '../actions'
import { View, ContentText, standardHalfMarginVert, ContentTextInput, screenTitleFontSize, palette, headerFontSize, closedGoalOpacity, getTextStyleForGoal, ContentButton} from '../styles'
import { getterInfluencesFromCollaboratorId, getterMembershipString, getterVisibleGoalFromId, getUserPerson, getVisibleGoals, getVisibleInfluences, makeIsGoalConsistent, makeIsGoalEffective, makeIsLegacyAssignedGoal, makeSortGoals } from '../selectors'
import { TableTyped } from '../components/Table'
import { calcGoalAggregateLikert, calcGoalsAverageScore, dateToLocalString, IdType, isGoalAndNotInfluence, isGoalOpenAndExpired, numberToLocString, sortedBy, sortedByMany } from '../utils'
import { LS } from '../loc/loc'
import { GoalInstanceComplete, GoalPhaseId, InfluenceInstance } from '../backendTypes'
import GoalStatus from './GoalStatus'
import { ExportGoalsButton, makeCreateGoalsXlsx } from '../xlsxUtils'
import { GoalListTable, makeTitleAndDate, titleFilterFunc } from './GenericGoalListTable'
import LikertProgressBar from './LikertProgressBar'
import { customSettings } from '../config'
import GoalsAggregateInfoPanel from './GoalsAggretateInfoPanel'

const connector = connect((s: RootState) => ({
    getMembershipString: getterMembershipString(s),
    visibleGoals: getVisibleGoals(s),
    userPerson: getUserPerson(s),
    visibleInfluences: getVisibleInfluences(s),
    getInfluencesFromCollaboratorId: getterInfluencesFromCollaboratorId(s),
    getVisibleGoalFromId: getterVisibleGoalFromId(s),
    isGoalConsistent: makeIsGoalConsistent(s),
    createGoalsXlsx: makeCreateGoalsXlsx(s),
    sortGoals: makeSortGoals(s),
    isLegacyAssignedGoal: makeIsLegacyAssignedGoal(s),
}))

type ExternalProps = {
    mode: 'all' | 'my',
}

type ComponentProps = ExternalProps & ConnectedProps<typeof connector>

const BaseGoalsByGoalTable: React.FunctionComponent<ComponentProps> = (props) => {
    const makeOpenGoalAction = (goalId: IdType): AppAction => ({type: ActionType.TRY_OPEN_GOAL_COMPLETE, goalId: goalId})
    const {mode, getMembershipString} = props
    const goals = props.sortGoals(mode === 'all' ?
            props.visibleGoals :
            props.visibleGoals.filter(g => g.assigneePersons.find(m => m.personId === props.userPerson.id)))
    const influences = sortedBy(mode === 'all' ?
        props.visibleInfluences :
        props.getInfluencesFromCollaboratorId(props.userPerson.id), i => i.weight, 'desc')
    const inflGoals = influences.map(infl => props.getVisibleGoalFromId(infl.goalId)!)
    const inflWeights = new Map(influences.map(i => [i.goalId, i.weight]))
    const goalsAndInflGoals = goals.concat(inflGoals)
    const items = (goals as (GoalInstanceComplete | InfluenceInstance)[]).concat(influences)
    return props.mode === 'all' ?
        <GoalListTable items={items} showFilters={true} />
    :
        <View style={{flexDirection: 'column'}}>
            <GoalsAggregateInfoPanel showAmadoriPercent={customSettings.showAmadoriPercent} totGoalNum={goals.length} totAvgScore={calcGoalsAverageScore(goalsAndInflGoals, inflWeights)} />
            <TableTyped
                fillContainer={'both'}
                columnNames={['', LS('goal'), LS('credits'), LS('manager')].concat(customSettings.showLikertProgressBar ? [LS('score')] : [])}
                columnWidths={['30px', 1, '75px', 1].concat(customSettings.showLikertProgressBar ? ['258px'] : [])}
                showFilters={[false, titleFilterFunc(items, props.getVisibleGoalFromId), false, true, false]}
                filterPlaceholders={['', LS('filterTitle'), '', LS('filterManager'), '']}
                items={items}
                itemToRow={(goalOrInfluence, index) => {
                    if (isGoalAndNotInfluence(goalOrInfluence)) {
                        const style = getTextStyleForGoal(goalOrInfluence)
                        const legacyNotClosed = props.isLegacyAssignedGoal(goalOrInfluence, props.userPerson.id) && goalOrInfluence.phaseId !== GoalPhaseId.Closed
                        return [
                            <View style={style}><GoalStatus goal={goalOrInfluence} lightBg={false} /></View>,
                            <View style={style}>{makeTitleAndDate(goalOrInfluence)}</View>,
                            <ContentText string={numberToLocString(goalOrInfluence.weight)} style={style} />,
                            <ContentText string={getMembershipString(goalOrInfluence.managerMembership)} style={{...style, color: legacyNotClosed ? palette.logo : undefined}} />,
                        ].concat(customSettings.showLikertProgressBar ? [
                            <View style={style}><LikertProgressBar aggregateValue={calcGoalAggregateLikert(goalOrInfluence)} lightBg={index % 2 === 0} /></View>,
                        ] : [])
                    } else {
                        const goal = props.getVisibleGoalFromId(goalOrInfluence.goalId)
                        return [
                            null,
                            <View style={{flexDirection: 'column', ...standardHalfMarginVert}}>
                                <ContentText style={{fontStyle: 'italic'}} string={goal?.title ?? LS('unknownLabel')} />
                            </View>,
                            numberToLocString(goalOrInfluence.weight),
                            props.getMembershipString(goalOrInfluence.managerMembership),
                        ].concat(customSettings.showLikertProgressBar ? [
                            goal === null ? null : <LikertProgressBar aggregateValue={calcGoalAggregateLikert(goal)} lightBg={index % 2 === 0} />
                        ] : [])
                    }
                }}
                onPressItem={item => isGoalAndNotInfluence(item) && props.dispatch(makeOpenGoalAction(item.id))}
                sideItem={<ExportGoalsButton goals={items.filter(item => isGoalAndNotInfluence(item)) as GoalInstanceComplete[]} />}
            />
        </View>
}

export default connector(BaseGoalsByGoalTable)