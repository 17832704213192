import React from 'react'
import {connect, ConnectedProps} from 'react-redux'
import { ActionType, AppAction, CreateSharedCollection } from '../actions'
import { SampleCollectionDefinition, SampleCollectionInstance, StringMaxLength } from '../backendTypes'
import ScreenWrapper from '../components/ScreenWrapper'
import { TableTyped } from '../components/Table'
import { LS } from '../loc/loc'
import { getSamplerGroupsById, getSharedSampleCollections, getterMembershipString, getterPersonFromId, getterSharedSampleCollection } from '../selectors'
import { RootState } from '../state'
import { Card, ContentButton, ContentLabel, ContentText, ContentTextInput, View, WrapperLabel } from '../styles'
import { IdType, isStringValid, makeShowListSelectorScreenTyped } from '../utils'
import { v4 as uuidv4 } from 'uuid'
import produce from 'immer'

export type CreateSharedCollectionScreenExternalProps = {
    editedCollectionId: null | IdType,
}

const connector = connect((s: RootState) => ({
    getSharedSampleCollection: getterSharedSampleCollection(s),
    getMembershipString: getterMembershipString(s),
    samplerGroupsById: getSamplerGroupsById(s),
    getPersonFromId: getterPersonFromId(s),
}), (dispatch) => ({
    dispatch: dispatch,
    showListSelectorScreen: makeShowListSelectorScreenTyped(dispatch),
}))

type ComponentProps = CreateSharedCollectionScreenExternalProps & ConnectedProps<typeof connector>

const ViewSharedCollectionScreen: React.FunctionComponent<ComponentProps> = props => {
    const editedColl = props.editedCollectionId !== null ? props.getSharedSampleCollection(props.editedCollectionId) : null
    const initialState: SampleCollectionDefinition = {
        id: editedColl?.id ?? uuidv4(),
        shared: editedColl?.shared ?? true,
        name: editedColl?.name ?? '',
        description: editedColl?.description ?? '',
        samplingNotes: editedColl?.samplingNotes ?? '',
        unitOfMeasure: editedColl?.unitOfMeasure ?? '',
        samplerGroupIds: editedColl?.samplerGroupIds ?? [],
        labelDefinitions: editedColl?.labelDefinitions ?? [],
    }
    const [state, setState] = React.useState(initialState)

    const inputValid = {
        collName: isStringValid(state.name, 1, StringMaxLength.mediumName),
        collUnitOfMeasure: state.unitOfMeasure !== null && isStringValid(state.unitOfMeasure.trim(), 1, StringMaxLength.mediumName),
        collSamplerGroups: state.samplerGroupIds.length > 0,
    }
    const allInputsValid = Object.values(inputValid).every(v => v)

    const showSelectSamplerGroups = () => props.showListSelectorScreen(
        LS('selectSamplerGroups'),
        [LS('name'), LS('members')],
        undefined,
        props.samplerGroupsById.valueSeq().sortBy(g => g.name).toArray(),
        g => [g.name, g.memberIds.map(pId => {
            const p = props.getPersonFromId(pId)
            return p.firstName + " " + p.lastName
        }).join(", ")],
        true,
        groups => setState(produce(state, s => {
            s.samplerGroupIds = groups.map(g => g.id)
        })))

    const headerItem = <>
        <ContentButton label={LS('ok')} onPress={() => {
            const createAction: AppAction = {
                type: editedColl ? ActionType.EDIT_SHARED_COLLECTION : ActionType.CREATE_SHARED_COLLECTION,
                collectionDef: state,
            }
            props.dispatch(createAction)
        }} enabled={allInputsValid} />
        <ContentButton label={LS('cancel')} onPress={() => props.dispatch({type: ActionType.NAVIGATION_POP})} />
    </>

    return <ScreenWrapper headerItem={headerItem}>
        <View style={{flexDirection: 'row'}}>
            <Card titleString={LS('collectionData')}>
                <WrapperLabel string={LS('title')}>
                    <ContentTextInput value={state.name} onChangeText={text => setState(produce(state, s => {
                        s.name = text
                    }))} valid={inputValid.collName} />
                </WrapperLabel>
                <WrapperLabel string={LS('description')}>
                <ContentTextInput value={state.description} onChangeText={text => setState(produce(state, s => {
                        s.description = text
                    }))} multiline />
                </WrapperLabel>
                <WrapperLabel string={LS('samplingNotes')}>
                <ContentTextInput value={state.samplingNotes} onChangeText={text => setState(produce(state, s => {
                        s.samplingNotes = text
                    }))} />
                </WrapperLabel>
                {state.unitOfMeasure !== null &&
                    <WrapperLabel string={LS('unitOfMeasure')}>
                        <ContentTextInput value={state.unitOfMeasure} onChangeText={text => setState(produce(state, s => {
                            s.unitOfMeasure = text
                        }))} valid={inputValid.collUnitOfMeasure} />
                    </WrapperLabel>
                }
                <WrapperLabel string={LS('samplers')}>
                    <ContentButton label={LS('select')} onPress={showSelectSamplerGroups} valid={inputValid.collSamplerGroups} />
                    {state.samplerGroupIds.map(groupId => {
                        const group = props.samplerGroupsById.get(groupId)
                        return group === undefined ? null :
                            <ContentLabel string={group.name} key={groupId} />
                    })}
                </WrapperLabel>
            </Card>
        </View>
    </ScreenWrapper>
}
export default connector(ViewSharedCollectionScreen)