import { Record, Map, List } from 'immutable'
import { Cycle, Person, Team, GoalTier, GoalTypology, GetUserDataValue, SampleCollectionInstance, SamplerGroup, GoalInstanceComplete, InfluenceInstance } from './backendTypes'
import { IdType, ResultCodeAndDetails } from './utils'
import { ScreenRouteId, defaultScreenRoute } from './routing'
import { PublicDbInstanceData } from './backendTypes'

interface RawCommonDataState {
    cycle: Cycle,
    userData: GetUserDataValue,
    personsById: Map<IdType, Person>,
    teamsById: Map<IdType, Team>,
    goalTiersById: Map<IdType, GoalTier>,
    goalTypologiesById: Map<IdType, GoalTypology>,
    visibleGoalsById: Map<IdType, GoalInstanceComplete>,
    sharedSampleCollectionsById: Map<IdType, SampleCollectionInstance>,
    samplerGroupsById: Map<IdType, SamplerGroup>,
    visibleInfluences: InfluenceInstance[],
}
export interface CommonDataState extends Record<RawCommonDataState>, RawCommonDataState {}

export interface ScreenStackItem {
    routeId: ScreenRouteId,
    props: object | null,
    title?: string,
}

export interface RawRootState {
    failure: {present: false} | ({present: true, data: ResultCodeAndDetails}),
    token: string,
    dbInstances: PublicDbInstanceData[] | null,
    selectedDbInstanceId: string | null,
    navigation: {screenStack: List<ScreenStackItem>},
    commonData: CommonDataState | null,
}
export interface RootState extends Record<RawRootState>, RawRootState {}

export const makeRootState: () => RootState = Record({
    failure: {present: false},
    token: "",
    dbInstances: null,
    selectedDbInstanceId: null,
    navigation: {screenStack: List([{routeId: defaultScreenRoute, props: null}])},
    commonData: null as (CommonDataState | null),
})
export const initialRootState: RootState = makeRootState()