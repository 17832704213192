import React, { CSSProperties } from 'react'
import {View, cardBgColor, standardHalfPadding, standardHalfMargin, standardSpaceHor, cardBoxShadow, CardHeaderText, PressableArea, cardBorderRadius, standardSpaceVert, palette, contentSingleLineHeight, ContentText, cardHeaderTextColor} from '../styles'

type TabCardProps = {
    items: {
        label: string,
        content: React.ReactElement,
        notificationNum?: number,
    }[],
    selectedIndex: number,
    onPressLabel: (index: number) => void,
    containerStyle?: CSSProperties,
}

export const TabCard: React.FunctionComponent<TabCardProps> = props =>
    <View style={{flexDirection: 'column', ...standardHalfMargin, ...props.containerStyle}}>
        <View style={{flexDirection: 'row'}}>
            {props.items.map((item, index) => {
                const selected = props.selectedIndex === index
                const tabStyle: CSSProperties = {
                    backgroundColor: selected ? cardBgColor : palette.buttonDisabled,
                    ...standardHalfPadding,
                    marginRight: standardSpaceHor,
                    zIndex: selected ? 1 : 0,
                    boxShadow: cardBoxShadow,
                    borderTopLeftRadius: cardBorderRadius,
                    borderTopRightRadius: cardBorderRadius,
                }
                const notifSize = contentSingleLineHeight * 0.75
                return <PressableArea onPress={() => props.onPressLabel(index)} style={{...tabStyle, flexDirection: 'row'}} key={index}>
                    <CardHeaderText titleString={item.label} style={{marginTop: 0, marginBottom: 0, ...(selected ? null : {color: palette.borderDefault})}} />
                    {item.notificationNum !== undefined && item.notificationNum > 0 &&
                        <View style={{width: notifSize, height: notifSize, borderRadius: notifSize / 2, backgroundColor: cardHeaderTextColor, justifyContent: 'center', alignItems: 'center'}}>
                            <ContentText style={{color: palette.white}} string={item.notificationNum.toLocaleString()} />
                        </View>
                    }
                </PressableArea>
            })}
        </View>
        <View style={{position: 'relative', backgroundColor: cardBgColor, boxShadow: cardBoxShadow, borderRadius: cardBorderRadius, borderTopLeftRadius: 0}}>
            {/* striscia bianca per coprire l'ombra delle linguette */}
            <View style={{position: 'absolute', zIndex: 2, top: 0, left: 0, right: 0, height: standardSpaceVert * 0.67, backgroundColor: cardBgColor}} />
            {props.items.map((item, index) => 
                <View style={{...standardHalfPadding, display: index === props.selectedIndex ? 'flex' : 'none'}} key={index}>
                    {item.content}
                </View>)
            }
        </View>
    </View>