import React, { CSSProperties } from 'react'
import { BaseText, ContentBigText, normalTextColor, palette, standardHalfMargin, standardHalfMarginVert, standardRadius, View } from '../styles'
import {likertMin, likertMax, likertStep, likertReached, likertValues} from '../screens/CreateKpiScreen'
import { amadoriLikertToPercent, numberToLocString, roundLikert, sumNumbers } from '../utils'
import { customSettings } from '../config'
import { LS } from '../loc/loc'
import LikertProgressBar from './LikertProgressBar'

type GoalsAggregateInfoPanelProps = {
    totGoalNum: number,
    totAvgScore: number,
    showAmadoriPercent: boolean,
}

const GoalsAggregateInfoPanel: React.FunctionComponent<GoalsAggregateInfoPanelProps> = props => {
    return customSettings.showLikertProgressBar ?
        <View style={{flexDirection: 'row', alignItems: 'center', marginTop: standardHalfMarginVert.marginTop * 2, marginBottom: standardHalfMarginVert.marginBottom * 2}}>
            <View style={{flexDirection: 'row', flex: 0.33}}>
                <ContentBigText string={LS('totGoalNumber') + ":"} />
                <ContentBigText string={numberToLocString(props.totGoalNum)} style={{color: normalTextColor}} />
            </View>
            {/*<View style={{flexDirection: 'row', flex: 0.34, justifyContent: 'flex-end'}}>
                <ContentBigText string={LS('averageScore') + ":"} />
                <ContentBigText tooltip={numberToLocString(props.totAvgScore, 3)} string={numberToLocString(props.totAvgScore, 1)} style={{color: normalTextColor}} />
                {props.showAmadoriPercent && <ContentBigText string={"(" + numberToLocString(amadoriLikertToPercent(props.totAvgScore), 2) + " %)"} style={{color: normalTextColor}} />}
            </View>*/}
            <View style={{flexDirection: 'row', flex: 0.67, justifyContent: 'flex-end', alignItems: 'center'}}>
                <ContentBigText string={LS('averageScore') + ":"} />
                {props.showAmadoriPercent && <ContentBigText string={"(" + numberToLocString(amadoriLikertToPercent(props.totAvgScore), 2) + " %)"} style={{color: normalTextColor}} />}
                <LikertProgressBar aggregateValue={props.totAvgScore} lightBg={false} sizeScale={1.5} />
            </View>
        </View>
    :
        null
}
export default GoalsAggregateInfoPanel