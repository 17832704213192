import { TenantConfig } from "./config"

export const configOverride: TenantConfig = {
    ktaskBackendUrl: "https://amadori.k-task.app/",
    ktaskClientUrl: "https://amadori.k-task.app/",
    oidc: {
        domain: 'k-rev.eu.auth0.com',
        clientId: 'S8XtcqBgouj5gYcPJcB9KifyYdV1YysP',
    },
    overriddenCustomSettings: {
        showAmadoriPercent: true,
    }
}