import React from 'react'
import {connect, ConnectedProps} from 'react-redux'
import { KpiDefinition, TeamMembership, SampleCollectionInstance, SampleCollectionDefinition, StringMaxLength, PlatformRoleId, KpiInstance } from '../backendTypes'
import ScreenWrapper from '../components/ScreenWrapper'
import { View, Card, ContentLabel, ContentTextInput, RadioCheckbox, SquareCheckbox, ContentButton, LabelBox, WrapperLabel } from '../styles'
import Imm from 'immutable'
import { RootState } from '../state'
import { getSharedSampleCollections, getterMembershipString, getterPersonFromId, getSamplerGroupsById, getterSharedSampleCollection, getUserPerson } from '../selectors'
import { makeShowListSelectorScreenTyped, updateState, isStringValid, stringToNumber, personHasPlatformRole, arraysEqual, getSignificantDigitsNum, stringToNumberNotNull, sortedBy, numberToLocString } from '../utils'
import { ActionType } from '../actions'
import produce from 'immer'
import { TableTyped } from '../components/Table'
import { LS } from '../loc/loc'
import { v4 as uuidv4 } from 'uuid'
import { customSettings } from '../config'
import { SmallIcon } from '../components/Icon'

export type CreateKpiScreenExternalProps = {
    creatingNewGoal: boolean,
    kpiData: KpiDefinition | KpiInstance | null,
    otherKpis: (KpiDefinition | KpiInstance)[],
    manager: TeamMembership | null | undefined,
    assignees: TeamMembership[],
    goalIsRoot: boolean,
    onConfirm: (kpi: KpiDefinition) => void,
}

const connector = connect((s: RootState) => ({
    sharedSampleCollections: getSharedSampleCollections(s),
    getSharedSampleCollection: getterSharedSampleCollection(s),
    getMembershipString: getterMembershipString(s),
    samplerGroupsById: getSamplerGroupsById(s),
    getPersonFromId: getterPersonFromId(s),
    userPerson: getUserPerson(s),
}), (dispatch) => ({
    dispatch: dispatch,
    showListSelectorScreen: makeShowListSelectorScreenTyped(dispatch),
}))

type ComponentProps = CreateKpiScreenExternalProps & ConnectedProps<typeof connector>

type KpiType = 'scalarNormal' | 'label' | 'scalarPercent'

type CreateKpiScreenState = {
    title: string,
    type: KpiType,
    thresholdStrings: Map<number, string>,
    collectionMode: 'newSingle' | 'newShared' | 'existingShared',
    selectedSharedCollection: SampleCollectionInstance | null,
    collectionDef: SampleCollectionDefinition,
    affectedCanSample: boolean,
}

export const likertMin = 1
export const likertMax = 5
export const likertReached = 3
export const likertStep = 0.5

export const likertValues = Array.from(Array((likertMax - likertMin + likertStep) / likertStep).keys()).map(v => v * likertStep + likertMin)

export const isLikertMandatory = (type: KpiType, v: number) =>
    type === 'scalarPercent' ?
        v % 1 === 0 :
        v === likertReached || v === likertMin

const makeDefaultState = (goalIsRoot: boolean): CreateKpiScreenState => ({
    title: "",
    type: 'scalarNormal',
    thresholdStrings: new Map(likertValues.map(v => [v, ""])),
    collectionMode: goalIsRoot ? 'newShared' : 'newSingle',
    selectedSharedCollection: null,
    collectionDef: {
        id: uuidv4(),
        shared: false,
        name: "",
        description: "",
        samplingNotes: "",
        unitOfMeasure: "",
        labelDefinitions: [],
        samplerGroupIds: [],
    },
    affectedCanSample: false,
})

class CreateKpiScreen extends React.PureComponent<ComponentProps, CreateKpiScreenState> {
    constructor(props: ComponentProps) {
        super(props)

        const defaultState = makeDefaultState(props.goalIsRoot)

        const {kpiData} = props
        if (kpiData === null) {
            this.state = defaultState
        } else {
            const sampleColl = 'sampleCollectionDef' in kpiData ? kpiData.sampleCollectionDef : kpiData.sampleCollection
            this.state = !kpiData ? defaultState : {
                title: kpiData.title,
                type: kpiData.stringThresholds ? 'label' : kpiData.withAchievementPercentage ? 'scalarPercent' : 'scalarNormal',
                thresholdStrings:
                    produce(
                        defaultState.thresholdStrings,
                        outThresh => {
                            const inThresh =
                                kpiData.numericThresholds ? new Map(kpiData.numericThresholds.map(t => [t.likertValue, numberToLocString(t.kpiValue)])) :
                                kpiData.stringThresholds ? new Map(kpiData.stringThresholds.map(t => [t.likertValue, sampleColl?.labelDefinitions[t.kpiValue] ?? ""])) :
                                new Map() as Map<number, string>
                            inThresh.forEach((v, k) => outThresh.set(k, v))
                            console.log(outThresh)
                        }
                    ),
                collectionMode: sampleColl ? (sampleColl.shared ? 'newShared' : 'newSingle') : 'existingShared',
                selectedSharedCollection: 'existingSampleCollectionId' in kpiData ? (
                    kpiData.existingSampleCollectionId ? props.getSharedSampleCollection(kpiData.existingSampleCollectionId) : null
                ) : 'sampleCollection' in kpiData ? (
                    kpiData.sampleCollection.shared ? kpiData.sampleCollection : null
                ) : null,
                collectionDef: sampleColl ?? defaultState.collectionDef,
                affectedCanSample: kpiData.affectedCanSample,
            }
        }
    }

    render() {
        const {props, state} = this
        const defaultState = makeDefaultState(props.goalIsRoot)
        const privileged = personHasPlatformRole(props.userPerson, PlatformRoleId.Privileged)
        const kpiNotInBackend = props.creatingNewGoal || props.kpiData === null
        const definingCollection = state.collectionMode !== 'existingShared'
        const shared = state.collectionMode === 'newShared' || state.collectionMode === 'existingShared'
        const scalar: boolean = state.type === 'scalarNormal' || state.type === 'scalarPercent'
        const showSelectSamplerGroup = () => props.showListSelectorScreen(
            LS('selectSamplerGroups'),
            [LS('name'), LS('members')],
            undefined,
            props.samplerGroupsById.valueSeq().sortBy(g => g.name).toArray(),
            g => [g.name, g.memberIds.map(pId => {
                const p = props.getPersonFromId(pId)
                return p.firstName + " " + p.lastName
            }).join(", ")],
            true,
            groups => this.setState({collectionDef: {...state.collectionDef, samplerGroupIds: groups.map(g => g.id)}}))
        const showSelectSharedCollection = () => props.showListSelectorScreen(
            LS('selectSharedCollection'),
            [LS('title'), LS('description')],
            undefined,
            props.sharedSampleCollections,
            c => [c.name, c.description],
            false,
            colls => this.setState({selectedSharedCollection: colls[0]}))
        const confirmPressed = () => {
            const filledThresh = Imm.Map(state.thresholdStrings).filter(s => s.length > 0)
            const labels = !scalar ? filledThresh.toOrderedMap().sortBy((_, k) => k).valueSeq().toArray() : []
            const ordinal = props.kpiData?.ordinal ?? (props.otherKpis.length === 0 ? 0 : 1 + Math.max.apply(null, props.otherKpis.map(k => k.ordinal).concat([-1])))
            const kpiDef: KpiDefinition = {
                id: props.kpiData ? props.kpiData.id : uuidv4(),
                ordinal: ordinal,
                title: state.title,
                weight: props.kpiData?.weight ?? null,
                affectedCanSample: state.affectedCanSample,
                withAchievementPercentage: state.type === 'scalarPercent',
                existingSampleCollectionId: definingCollection ? null : state.selectedSharedCollection?.id ?? null,
                sampleCollectionDef: definingCollection ? {...state.collectionDef, labelDefinitions: labels, shared: state.collectionMode === 'newShared'} : null,
                numericThresholds: scalar ? filledThresh.map((s, n) => ({
                    likertValue: n,
                    kpiValue: stringToNumberNotNull(s),
                })).valueSeq().sortBy(t => t.likertValue).toArray() : null,
                stringThresholds: state.type === 'label' ? filledThresh.map((s, n) => ({
                    likertValue: n,
                    kpiValue: labels.indexOf(s),
                })).valueSeq().sortBy(t => t.likertValue).toArray() : null,
            }
            props.dispatch({type: ActionType.NAVIGATION_POP})
            props.onConfirm(kpiDef)
        }

        const changeOneThresholdString = (s: CreateKpiScreenState, t: {key: number, val: string}): void => {
            s.thresholdStrings.set(t.key, t.val)
        }
        const changeAllThresholdStrings = (s: CreateKpiScreenState, ts: typeof state.thresholdStrings): void => {
            s.thresholdStrings = ts
        }
        const changeCollectionMode = (s: CreateKpiScreenState, cm: typeof state.collectionMode): void => {
            s.collectionMode = cm
            s.collectionDef = defaultState.collectionDef
            s.selectedSharedCollection = null
            s.affectedCanSample = false
        }
        const changeKpiType = (s: CreateKpiScreenState, type: typeof state.type): void => {
            s.type = type
            changeAllThresholdStrings(s, defaultState.thresholdStrings)
            changeCollectionMode(s, type === 'label' ? 'newSingle' : state.collectionMode)
        }

        // usato per modifica qualitativi esistenti
        const isThresholdPresentInKpiDef = (likert: number) => !!props.kpiData?.stringThresholds?.find(t => t.likertValue === likert)

        const isThresholdValid = (likert: number) => {
            const kpiStr = state.thresholdStrings.get(likert)
            if (kpiStr === undefined) throw new Error()
            if (scalar) {
                if (kpiStr === '' && !isLikertMandatory(state.type, likert)) return true
                const kpiNum = stringToNumber(kpiStr)
                if (kpiNum === null) return false
                const kpiValues = Array.from(state.thresholdStrings.entries()).filter(([_, k]) => k !== '').map(([_, k]) => stringToNumber(k))
                const validKpiValues = kpiValues.filter(k => k !== null).map(k => k ?? 0)
                const sortedKpiValues = produce(validKpiValues, kv => {
                    kv.sort((a, b) => a - b)
                })
                const reversedSortedKpiValues = produce(sortedKpiValues, skv => {
                    skv.reverse()
                })
                if (!(arraysEqual(validKpiValues, sortedKpiValues) || arraysEqual(validKpiValues, reversedSortedKpiValues))) return false
                if (!(kpiValues.filter(k => k === kpiNum).length <= 1)) return false
                return true
            } else {
                const trimmedKpiValues = Array.from(state.thresholdStrings.entries()).map(([_, k]) => k.trim()).filter((k) => k !== '')
                return (!(kpiStr !== '') || kpiStr.trim() !== '') && // non accetta solo whitespace
                    (!(likert === likertReached || likert === likertMin) || kpiStr !== '') &&
                    (!(kpiStr !== '') || isStringValid(kpiStr, 1, StringMaxLength.mediumName)) &&
                    trimmedKpiValues.filter(k => k.toLocaleLowerCase() === kpiStr.trim().toLocaleLowerCase()).length <= 1 &&
                    (!(!kpiNotInBackend && !scalar) || (kpiStr !== '') === isThresholdPresentInKpiDef(likert)) // se sta modificando kpi esistente e qualitativo, le soglie definite devono restare le stesse
            }
        }

        const inputValid = {
            title: isStringValid(state.title, 1, StringMaxLength.mediumName) &&
                props.otherKpis.every(other => other.title.toLocaleLowerCase().trim() !== state.title.toLocaleLowerCase().trim()),
            thresholds: ((): boolean[] => {
                return likertValues.map(isThresholdValid)
            })(),
            collName: (state.collectionMode === 'newShared') ? isStringValid(state.collectionDef.name, 1, StringMaxLength.mediumName) : true,
            collUnitOfMeasure: definingCollection && scalar ?
                state.collectionDef.unitOfMeasure !== null && isStringValid(state.collectionDef.unitOfMeasure.trim(), 1, StringMaxLength.mediumName) :
                state.collectionDef.unitOfMeasure === null || state.collectionDef.unitOfMeasure.length === 0,
            collSamplerGroups: state.collectionMode === 'newShared' ? state.collectionDef.samplerGroupIds.length > 0 : state.collectionDef.samplerGroupIds.length === 0,
            selectedSharedCollection: state.collectionMode === 'existingShared' ? state.selectedSharedCollection !== null : state.selectedSharedCollection === null,
        }
        const allInputsValid = Object.values(inputValid).every(v => typeof v === 'object' ? v.every(v2 => v2) : v)

        const getThreshStr = (likert: number) => {
            const thresh = state.thresholdStrings.get(likert)
            if (thresh === undefined) throw new Error()
            return thresh
        }
        const getThreshNum = (likert: number) => {
            return stringToNumber(getThreshStr(likert))
        }

        const thresholdNumberEntries: [number, number | null][] = Array.from(state.thresholdStrings.entries()).map(([l, k]) => [l, stringToNumber(k)])
        const filledNumberThresholds = thresholdNumberEntries.filter(([l, k]) => k !== null)
        const calculateThresholdsEnabled = scalar && filledNumberThresholds.length === 2
        const onPressCalculateThresholds = !calculateThresholdsEnabled ? undefined : () => {
            const [[x1, y1], [x2, y2]] = filledNumberThresholds
            if (y1 === null || y2 === null) throw new Error()
            const newThreshNumbers = thresholdNumberEntries.map(([x, y]) => x === x1 ? [x1, y1] : x === x2 ? [x2, y2] :
                [x, y1 + (y2 - y1) / (x2 - x1) * (x - x1)])
            const sigDigits = 3 + Math.max(getSignificantDigitsNum(y1), getSignificantDigitsNum(y2))
            this.setState({thresholdStrings: new Map(newThreshNumbers.map(([l, k]) =>
                [l, numberToLocString(Number.parseFloat(k.toPrecision(sigDigits)))]))})
        }

        const headerButtons =
            <React.Fragment>
                <ContentButton label={LS('ok')} onPress={allInputsValid ? confirmPressed : undefined} />
                <ContentButton label={LS('cancel')} onPress={() => props.dispatch({type: ActionType.NAVIGATION_POP})} />
            </React.Fragment>

        return (
            <ScreenWrapper headerItem={headerButtons}>
                <View style={{flexDirection: 'column', flex: 1}}>
                    <View style={{flexDirection: 'row', alignItems: 'flex-start'}}>
                        <Card titleString={LS('kpiData')} containerStyle={{flex: 0.5}}>
                            <ContentLabel string={LS('kpiTitle')} />
                            <ContentTextInput value={state.title} onChangeText={text => this.setState({title: text})} valid={inputValid.title} editable={true} />

                            <WrapperLabel string={LS('type')}>
                                <View style={{flexDirection: 'column', alignContent: 'flex-start'}}>
                                    <RadioCheckbox string={LS('kpiTypeQuantitativeThreshold')} tooltip={LS('quantitativeThresholdTooltip')} checked={state.type === 'scalarNormal'} onPress={() => this.setState(produce(state, s => changeKpiType(s, 'scalarNormal')))} enabled={kpiNotInBackend} />
                                    <RadioCheckbox string={LS('kpiTypeQuantitativeContinuous')} tooltip={LS('quantitativeContinuousTooltip')} checked={state.type === 'scalarPercent'} onPress={() => this.setState({type: 'scalarPercent', thresholdStrings: defaultState.thresholdStrings})} enabled={kpiNotInBackend} />
                                    {!props.goalIsRoot && <RadioCheckbox string={LS('kpiTypeQualitative')} tooltip={LS('qualitativeTooltip')} checked={state.type === 'label'} onPress={() => updateState(this, changeKpiType, 'label')} enabled={kpiNotInBackend} />}
                                </View>
                            </WrapperLabel>

                            <WrapperLabel string={LS('thresholds')} tooltip={LS('thresholdsTooltip')}>
                                {
                                    likertValues.map((v, index) => {
                                        return <View style={{flexDirection: 'row', flex: 1, alignItems: 'center'}} key={v}>
                                            <LabelBox string={numberToLocString(v)} style={{width: 80}} highlighted={isLikertMandatory(state.type, v)} />
                                            <ContentTextInput
                                                value={getThreshStr(v)}
                                                onChangeText={text => updateState(this, changeOneThresholdString, {key: v, val: text})}
                                                style={{flex: 1}}
                                                valid={inputValid.thresholds[index]}
                                                editable={kpiNotInBackend || scalar || (!scalar && isThresholdPresentInKpiDef(v))}
                                            />
                                        </View>
                                    })
                                }
                                {kpiNotInBackend &&
                                    <View style={{flexDirection: 'row'}}>
                                        {/*<ContentButton label={LS('calculateLinearly')} onPress={onPressCalculateThresholds} />*/}
                                        <ContentButton label={LS('clearThresholds')} onPress={() => this.setState({thresholdStrings: defaultState.thresholdStrings})} />
                                    </View>
                                }
                            </WrapperLabel>
                        </Card>
                        <Card titleString={LS('collectionData')} containerStyle={{flex: 0.5}}>
                            {kpiNotInBackend ?
                                !(!privileged && !customSettings.showSharedCollectionsForNormal) && // non mostra scelte se non è privilegiato e non deve mostrare le collection condivise
                                    <React.Fragment>
                                        {!props.goalIsRoot &&
                                            <RadioCheckbox string={LS('newSingleCollection')} checked={state.collectionMode === 'newSingle'} onPress={() => updateState(this, changeCollectionMode, 'newSingle')} enabled={kpiNotInBackend} />}
                                        {scalar && <React.Fragment>
                                            {privileged &&
                                                <RadioCheckbox string={LS('newSharedCollection')} checked={state.collectionMode === 'newShared'} onPress={() => updateState(this, changeCollectionMode,  'newShared')} enabled={kpiNotInBackend} />
                                            }
                                            {(privileged || customSettings.showSharedCollectionsForNormal) &&
                                                <RadioCheckbox string={LS('existingCollection')} checked={state.collectionMode === 'existingShared'} onPress={() => updateState(this, changeCollectionMode, 'existingShared')} enabled={kpiNotInBackend} />
                                            }
                                        </React.Fragment>}
                                    </React.Fragment>
                            :
                                <React.Fragment>
                                    <RadioCheckbox string={LS('singleCollection')} checked={state.collectionMode === 'newSingle'} onPress={() => {}} enabled={false} />
                                    <RadioCheckbox string={LS('sharedCollection')} checked={state.collectionMode !== 'newSingle'} onPress={() => {}} enabled={false} />
                                </React.Fragment>
                            }

                            {kpiNotInBackend && !definingCollection &&
                                <ContentButton label={LS('select')} icon={<SmallIcon name='arrow-right' />} onPress={showSelectSharedCollection} valid={inputValid.selectedSharedCollection} />}

                            {(definingCollection || state.selectedSharedCollection) && <React.Fragment>
                                {shared && <React.Fragment>
                                    <ContentLabel string={LS('title')} />
                                    <ContentTextInput value={definingCollection ? state.collectionDef.name : state.selectedSharedCollection?.name ?? ""}
                                        editable={definingCollection}
                                        onChangeText={text => this.setState({collectionDef: {...state.collectionDef, name: text}})}
                                        valid={inputValid.collName} />

                                    <ContentLabel string={LS('description')} />
                                    <ContentTextInput value={definingCollection ? state.collectionDef.description : state.selectedSharedCollection?.description ?? ""}
                                        editable={definingCollection}
                                        multiline={true}
                                        onChangeText={text => this.setState({collectionDef: {...state.collectionDef, description: text}})} />
                                </React.Fragment>}

                                {(state.type === 'scalarNormal' || state.type === 'scalarPercent') &&
                                    <WrapperLabel string={LS('unitOfMeasure')} tooltip={LS('unitOfMeasureTooltip')}>
                                        <ContentTextInput value={definingCollection ? state.collectionDef.unitOfMeasure ?? "" : state.selectedSharedCollection?.unitOfMeasure ?? ""}
                                            editable={definingCollection}
                                            onChangeText={text => this.setState({collectionDef: {...state.collectionDef, unitOfMeasure: text}})} valid={inputValid.collUnitOfMeasure} />
                                    </WrapperLabel>
                                }

                                {(privileged || !(state.collectionMode === 'newSingle' && !customSettings.showSingleCollectionSamplersForNormal)) &&
                                    <WrapperLabel string={LS('samplers')} tooltip={LS('samplersTooltip')}>
                                        { state.collectionMode === 'newSingle' ?
                                            <React.Fragment>
                                                <SquareCheckbox string={LS('manager') + ":\n" + props.getMembershipString(props.manager || null)} checked={true} enabled={false} onPress={() => {}} />
                                                <SquareCheckbox string={LS('assignees') + ":\n" + (props.assignees.length === 0 ? '-' : props.assignees.map(m => props.getMembershipString(m)).join('\n'))}
                                                    checked={state.affectedCanSample} onPress={() => this.setState({affectedCanSample: !state.affectedCanSample})} enabled={true} />
                                            </React.Fragment>
                                        : state.collectionMode === 'newShared' ?
                                            <React.Fragment>
                                                {kpiNotInBackend && <ContentButton label={LS('select')} icon={<SmallIcon name='arrow-right' />} onPress={showSelectSamplerGroup} valid={inputValid.collSamplerGroups} />}
                                                <TableTyped items={state.collectionDef.samplerGroupIds.map(gId => props.samplerGroupsById.get(gId))}
                                                    itemToRow={g => [g ? g.name : "NOT_FOUND"]} />
                                            </React.Fragment>
                                        : state.collectionMode === 'existingShared' && state.selectedSharedCollection ?
                                            <TableTyped items={state.selectedSharedCollection.samplerGroupIds.map(gId => props.samplerGroupsById.get(gId))}
                                                itemToRow={g => [g ? g.name : "NOT_FOUND"]} />
                                        : null }
                                    </WrapperLabel>
                                }

                                <WrapperLabel string={LS('samplingNotes')}>
                                    <ContentTextInput value={definingCollection ? state.collectionDef.samplingNotes : state.selectedSharedCollection?.samplingNotes ?? ""}
                                        editable={definingCollection}
                                        multiline={true}
                                        onChangeText={text => this.setState({collectionDef: {...state.collectionDef, samplingNotes: text}})} />
                                </WrapperLabel>
                            </React.Fragment>}
                        </Card>
                    </View>
                </View>
            </ScreenWrapper>
        )
    }
}

export default connector(CreateKpiScreen)