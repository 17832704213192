import Redux, { Action } from 'redux'
import {CommonDataState, ScreenStackItem} from './state'
import {ResultCodeAndDetails, IdType} from './utils'
import { GoalDefinition, ReparentGoalArgs, CreateSamplesArgsEntry, ReassignGoalArgs, InfluenceRef, InfluenceDefinition, SampleCollectionDefinition, EditSharedCollectionCmd, GoalPhaseId, ChangeGoalPhaseArgs } from './backendTypes'
import { PublicDbInstanceData } from './backendTypes'

export enum ActionType {
    CRITICAL_ERROR = 'CRITICAL_ERROR',
    INITIALIZE = 'INITIALIZE',
    RESET_STATE = 'RESET_STATE',
    LOGOUT = 'LOGOUT',

    CHOOSE_DB_INSTANCE = 'CHOOSE_DB_INSTANCE',
    FETCH_COMMON_DATA_REQUEST = 'FETCH_COMMON_DATA_REQUEST',
    FETCH_COMMON_DATA_RESPONSE = 'FETCH_COMMON_DATA_RESPONSE',

    AUTHENTICATE_USER_REQUEST = 'AUTHENTICATE_USER_REQUEST',
    AUTHENTICATE_USER_RESPONSE = 'AUTHENTICATE_USER_RESPONSE',
    
    SET_TOKEN = 'SET_TOKEN',
    SET_DB_INSTANCES = 'SET_DB_INSTANCES',
    SET_SELECTED_DB_INSTANCE_ID = 'SET_SELECTED_DB_INSTANCE_ID',

    NAVIGATE_TO = 'NAVIGATE_TO',
    NAVIGATION_PUSH = 'NAVIGATION_PUSH',
    NAVIGATION_POP = 'NAVIGATION_POP',

    CREATE_GOAL = 'CREATE_GOAL',
    TRY_OPEN_GOAL_COMPLETE = 'TRY_OPEN_GOAL_COMPLETE',
    DELETE_GOAL = 'DELETE_GOAL',
    ADD_SAMPLES = 'ADD_SAMPLES',
    DELETE_SAMPLE = 'DELETE_SAMPLE',

    CREATE_INFLUENCES = 'CREATE_INFLUENCES',
    AFFECT_INFLUENCES = 'AFFECT_INFLUENCES',
    EDIT_GOAL = 'EDIT_GOAL',
    EXTENDED_EDIT_GOAL = 'EXTENDED_EDIT_GOAL',
    CHANGE_GOAL_PHASE = 'CHANGE_GOAL_PHASE',
    CREATE_SHARED_COLLECTION = 'CREATE_SHARED_COLLECTION',
    EDIT_SHARED_COLLECTION = 'EDIT_SHARED_COLLECTION',
    DELETE_SHARED_COLLECTION = 'DELETE_SHARED_COLLECTION',

    MOVE_GOALS_AND_INFLUENCES_WITH_PERSONS = 'MOVE_GOALS_AND_INFLUENCES_WITH_PERSONS',
    SET_ALL_MISSING_PARENTS_TO_ROOT = 'SET_ALL_MISSING_PARENTS_TO_ROOT',
    CREATE_DUMMY_GOALS = 'CREATE_DUMMY_GOALS',

    EXPORT_XLS = 'EXPORT_XLS',
    EXPORT_XLS_EXPANDED = 'EXPORT_XLS_EXPANDED',
}

interface BaseAction extends Redux.Action {
}

interface Initialize extends BaseAction {
    type: ActionType.INITIALIZE,
}

interface ResetState extends BaseAction {
    type: ActionType.RESET_STATE,
}

interface Logout extends BaseAction {
    type: ActionType.LOGOUT,
}

export interface ChooseDbInstance extends BaseAction {
    type: ActionType.CHOOSE_DB_INSTANCE,
}

interface FetchCommonDataRequest extends BaseAction {
    type: ActionType.FETCH_COMMON_DATA_REQUEST,
}

interface FetchCommonDataResponse extends BaseAction {
    type: ActionType.FETCH_COMMON_DATA_RESPONSE,
    payload: CommonDataState,
}

interface CriticalError extends BaseAction {
    type: ActionType.CRITICAL_ERROR,
    data: ResultCodeAndDetails,
}

interface AuthenticateUserRequest extends BaseAction {
    type: ActionType.AUTHENTICATE_USER_REQUEST,
}

export interface AuthenticateUserResponse extends BaseAction {
    type: ActionType.AUTHENTICATE_USER_RESPONSE,
    success: boolean,
    token: string,
    state: string,
}

interface SetToken extends BaseAction {
    type: ActionType.SET_TOKEN,
    token: string,
}

interface SetDbInstances extends BaseAction {
    type: ActionType.SET_DB_INSTANCES,
    dbInstances: PublicDbInstanceData[],
}

interface SetSelectedDbInstanceId extends BaseAction {
    type: ActionType.SET_SELECTED_DB_INSTANCE_ID,
    dbInstanceId: string | null,
}

export interface NavigateTo extends BaseAction {
    type: ActionType.NAVIGATE_TO,
    stackItem: ScreenStackItem,
}

export interface NavigationPush extends BaseAction {
    type: ActionType.NAVIGATION_PUSH,
    stackItem: ScreenStackItem,
}

export interface NavigationPop extends BaseAction {
    type: ActionType.NAVIGATION_POP,
}

export interface CreateGoal extends BaseAction {
    type: ActionType.CREATE_GOAL,
    goalDef: GoalDefinition,
}

export interface TryOpenGoalComplete extends BaseAction {
    type: ActionType.TRY_OPEN_GOAL_COMPLETE,
    goalId: IdType,
}

export interface DeleteGoal extends BaseAction {
    type: ActionType.DELETE_GOAL,
    goalId: IdType,
}

export interface AddSamples extends BaseAction {
    type: ActionType.ADD_SAMPLES,
    argsEntries: CreateSamplesArgsEntry[],
    backToSharedCollection: boolean,
}

export interface DeleteSample extends BaseAction {
    type: ActionType.DELETE_SAMPLE,
    sampleId: IdType,
}

export interface CreateInfluences extends BaseAction {
    type: ActionType.CREATE_INFLUENCES,
    influences: InfluenceDefinition[],
}

export interface AffectInfluences extends BaseAction {
    type: ActionType.AFFECT_INFLUENCES,
    toDelete: InfluenceRef[],
    toCreate: InfluenceDefinition[],
    toEdit: InfluenceDefinition[],
}

export interface EditGoal extends BaseAction {
    type: ActionType.EDIT_GOAL,
    goalDef: GoalDefinition,
}

export interface ExtendedEditGoal extends BaseAction {
    type: ActionType.EXTENDED_EDIT_GOAL,
    goalDef: GoalDefinition,
    reparentArgs: ReparentGoalArgs,
    reassignArgs: ReassignGoalArgs,
}

export interface ChangeGoalPhase extends BaseAction {
    type: ActionType.CHANGE_GOAL_PHASE,
    args: ChangeGoalPhaseArgs,
}

export interface CreateSharedCollection extends BaseAction {
    type: ActionType.CREATE_SHARED_COLLECTION,
    collectionDef: SampleCollectionDefinition,
}

export interface EditSharedCollection extends BaseAction {
    type: ActionType.EDIT_SHARED_COLLECTION,
    collectionDef: SampleCollectionDefinition,
}

export interface DeleteSharedCollection extends BaseAction {
    type: ActionType.DELETE_SHARED_COLLECTION,
    collectionId: IdType,
}

export interface MoveGoalsAndInfluencesWithPersons extends BaseAction {
    type: ActionType.MOVE_GOALS_AND_INFLUENCES_WITH_PERSONS,
    time: Date,
}

export interface SetAllMissingParentsToRoot extends BaseAction {
    type: ActionType.SET_ALL_MISSING_PARENTS_TO_ROOT,
    rootId: IdType,
}

export interface CreateDummyGoals extends BaseAction {
    type: ActionType.CREATE_DUMMY_GOALS,
}

export interface ExportXls extends BaseAction {
    type: ActionType.EXPORT_XLS,
}
export interface ExportXlsExpanded extends BaseAction {
    type: ActionType.EXPORT_XLS_EXPANDED,
}

export type AppAction =
    CriticalError |
    Initialize |
    ResetState |
    Logout |
    ChooseDbInstance |
    FetchCommonDataRequest |
    FetchCommonDataResponse |
    AuthenticateUserRequest |
    AuthenticateUserResponse |
    SetToken |
    SetDbInstances |
    SetSelectedDbInstanceId |
    NavigateTo |
    NavigationPush |
    NavigationPop |
    CreateGoal |
    TryOpenGoalComplete |
    DeleteGoal |
    AddSamples |
    DeleteSample |
    CreateInfluences |
    AffectInfluences |
    EditGoal |
    ExtendedEditGoal |
    ChangeGoalPhase |
    CreateSharedCollection |
    EditSharedCollection |
    DeleteSharedCollection |
    MoveGoalsAndInfluencesWithPersons |
    SetAllMissingParentsToRoot |
    CreateDummyGoals |
    ExportXls |
    ExportXlsExpanded