import React from 'react'
import {connect, ConnectedProps} from 'react-redux'
import ScreenWrapper from '../components/ScreenWrapper'
import { View, Card, ContentButton, LabelBox, WrapperLabel, ContentText } from '../styles'
import { RootState } from '../state'
import { getSharedSampleCollections, getterMembershipString, getterPersonFromId, getSamplerGroupsById, getterSharedSampleCollection, getUserPerson, getterVisibleGoalFromId } from '../selectors'
import { IdType, makeShowListSelectorScreenTyped, numberToLocString } from '../utils'
import { ActionType } from '../actions'
import { TableTyped } from '../components/Table'
import { locKpiType, LS } from '../loc/loc'
import { likertValues, likertReached, likertMin, isLikertMandatory } from './CreateKpiScreen'

export type ViewKpiScreenExternalProps = {
    goalId: IdType,
    kpiId: IdType,
}

const connector = connect((s: RootState) => ({
    getVisibleGoalFromId: getterVisibleGoalFromId(s),
    sharedSampleCollections: getSharedSampleCollections(s),
    getSharedSampleCollection: getterSharedSampleCollection(s),
    getMembershipString: getterMembershipString(s),
    samplerGroupsById: getSamplerGroupsById(s),
    getPersonFromId: getterPersonFromId(s),
    userPerson: getUserPerson(s),
}), (dispatch) => ({
    dispatch: dispatch,
    showListSelectorScreen: makeShowListSelectorScreenTyped(dispatch),
}))

type ComponentProps = ViewKpiScreenExternalProps & ConnectedProps<typeof connector>

class CreateKpiScreen extends React.PureComponent<ComponentProps> {
    render() {
        const {props} = this
        const goal = props.getVisibleGoalFromId(props.goalId)

        if (goal === null) return "goal id not found " + props.goalId

        const kpiData = goal.kpis.find(k => k.id === props.kpiId)
        if (!kpiData) throw new Error()

        const scalar = kpiData.numericThresholds !== null

        const getThreshStr = (likert: number) => {
            const thresh = kpiData.stringThresholds?.find(t => t.likertValue === likert)
            return thresh ? kpiData.sampleCollection.labelDefinitions[thresh.kpiValue] : null
        }
        const getThreshNum = (likert: number) => {
            return kpiData.numericThresholds?.find(t => t.likertValue === likert)?.kpiValue ?? null
        }

        const headerButtons =
            <ContentButton label={LS('back')} onPress={() => props.dispatch({type: ActionType.NAVIGATION_POP})} />

        return (
            <ScreenWrapper headerItem={headerButtons}>
                <View style={{flexDirection: 'column', flex: 1}}>
                    <View style={{flexDirection: 'row', alignItems: 'flex-start'}}>
                        <Card titleString={LS('kpiData')} containerStyle={{flex: 0.5}}>
                            <WrapperLabel string={LS('kpiTitle')}>
                                <ContentText string={kpiData.title} />
                            </WrapperLabel>

                            <WrapperLabel string={LS('type')}>
                                <ContentText string={locKpiType(kpiData.numericThresholds !== null, kpiData.withAchievementPercentage)} />
                            </WrapperLabel>

                            <WrapperLabel string={LS('thresholds')} />
                            {
                                likertValues.map((v, index) => {
                                    const highlighted = isLikertMandatory(!scalar ? 'label' : kpiData.withAchievementPercentage ? 'scalarPercent' : 'scalarNormal', v)

                                    let threshValStrOrNull: string | null
                                    if (scalar) {
                                        const threshNum = getThreshNum(v)
                                        threshValStrOrNull = threshNum === null ? null : numberToLocString(threshNum)
                                    } else {
                                        threshValStrOrNull = getThreshStr(v)
                                    }
                                    const threshValStr = threshValStrOrNull ?? ''

                                    return <View style={{flexDirection: 'row', flex: 1, alignItems: 'center'}} key={v}>
                                        <LabelBox string={numberToLocString(v)} style={{width: 80}} highlighted={highlighted} />
                                        <LabelBox string={threshValStr}
                                            style={{flex: 1, alignItems: 'flex-start'}} />
                                    </View>
                                })
                            }
                        </Card>
                        <Card titleString={LS('collectionData')} containerStyle={{flex: 0.5}}>
                            <WrapperLabel string={LS('type')}>
                                <ContentText string={kpiData.sampleCollection.shared ? LS('sharedCollection') : LS('singleCollection')} />
                            </WrapperLabel>

                            <React.Fragment>
                                {kpiData.sampleCollection.shared && <>
                                    <WrapperLabel string={LS('title')}>
                                        <ContentText string={kpiData.sampleCollection.name} />
                                    </WrapperLabel>
                                    <WrapperLabel string={LS('description')}>
                                        <ContentText string={kpiData.sampleCollection.description} />
                                    </WrapperLabel>
                                </>}

                                <WrapperLabel string={LS('samplingNotes')}>
                                    <ContentText string={kpiData.sampleCollection.samplingNotes} />
                                </WrapperLabel>

                                {scalar &&
                                    <WrapperLabel string={LS('unitOfMeasure')}>
                                        <ContentText string={kpiData.sampleCollection.unitOfMeasure ?? '<ERROR>'} />
                                    </WrapperLabel>}
                                
                                <WrapperLabel string={LS('samplers')}>
                                    { !kpiData.sampleCollection.shared ?
                                        <React.Fragment>
                                            <ContentText string={LS('manager') + ":\n" + props.getMembershipString(goal.managerMembership || null)} />
                                            {kpiData.affectedCanSample && <ContentText string={LS('assignees') + ":\n" + (goal.assigneePersons.length === 0 ? '-' : goal.assigneePersons.map(m => props.getMembershipString(m)).join('\n'))} />}
                                        </React.Fragment>
                                    :
                                        <TableTyped items={kpiData.sampleCollection.samplerGroupIds.map(gId => props.samplerGroupsById.get(gId))}
                                            itemToRow={g => [g ? g.name : "NOT_FOUND"]} />
                                    }
                                </WrapperLabel>
                            </React.Fragment>
                        </Card>
                    </View>
                </View>
            </ScreenWrapper>
        )
    }
}

export default connector(CreateKpiScreen)