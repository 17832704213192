import React, {CSSProperties} from 'react'
import produce from 'immer'
import { PressableArea, palette, ContentText, View, ScrollView, ContentTextInput, standardHalfMargin, tableBorderRadius, standardHalfPadding, contentSingleLineHeight, standardHalfPaddingHor, standardHalfPaddingVert, BaseTextProps, ContentButton } from '../styles'
import { LS } from '../loc/loc'
import { StringifySet } from '../utils'
import { SmallIcon } from './Icon'

export type TableItemType = string | React.ReactElement | null | undefined

export const TableRow: React.FunctionComponent<{
        items: TableItemType[],
        header: boolean,
        containerStyle?: CSSProperties,
        onPress?: () => void,
        columnWidths: (number | string)[] | undefined,
        onHoverStart?: () => void,
        onHoverEnd?: () => void,
        forceShowHover?: boolean,
    }> = props => {
    const getColWidthBasis = (index: number) => props.columnWidths === undefined ? '0%' : typeof props.columnWidths[index] === 'string' ? props.columnWidths[index] : '0%'
    const getColWidthGrow = (index: number) => {
        if (props.columnWidths === undefined) return 1
        const w = props.columnWidths[index]
        return typeof w === 'number' ? w : undefined
    }
    const rowElem = (
        <PressableArea
            onPress={props.onPress}
            enabled={props.forceShowHover}
            style={{flexDirection: 'row', backgroundColor: props.header ? palette.textInputFocus : palette.white, ...props.containerStyle}}
            hoveredStyle={{backgroundColor: palette.tableHover}}
            onHoverStart={props.onHoverStart}
            onHoverEnd={props.onHoverEnd}
        >
            {props.items.filter(item => item !== undefined).map((item, index) => {
                const contentStyle = props.header ? {
                    color: palette.white,
                } : null
                const cellStyle: CSSProperties | null = props.header && index > 0 && item ? {
                    borderLeftStyle: 'solid',
                    borderLeftWidth: 1,
                    borderLeftColor: '#C8B6B0',
                } : null
                return (
                    <View style={{flexGrow: getColWidthGrow(index), flexBasis: getColWidthBasis(index), overflow: 'hidden', flexDirection: 'row', alignSelf: 'stretch', alignItems: 'center', ...cellStyle, minHeight: contentSingleLineHeight}} key={index}>
                        <View style={{flex: 1, overflow: 'hidden', ...standardHalfPaddingHor}}>{
                            typeof item === 'string' ?
                                <ContentText style={{flex: 1, ...contentStyle}} string={item} /> :
                                (item ? React.cloneElement(item, {flex: 1, ...contentStyle}) : null)
                        }</View>
                    </View>
                )
            })}
        </PressableArea>
    )
    return rowElem
}

export const TableFilterRow: React.FunctionComponent<{
        filters: (string | null)[],
        onFiltersChange: (filters: (string | null)[]) => void,
        columnWidths: (number | string)[] | undefined,
        sideItem?: React.ReactElement,
        placeholders?: string[],
    }> = props => {
    const onChangeText = (index: number, text: string) => {
        const newFilters = produce(props.filters, fs => {
            fs[index] = text
        })
        props.onFiltersChange(newFilters)
    }
    const inputs = props.filters.map((f, index) => f === null ? null : <ContentTextInput value={f} placeholder={props.placeholders && props.placeholders[index]} onChangeText={text => onChangeText(index, text)} icon={<SmallIcon name='filter' />} style={{maxWidth: 200}} />)
    return <View style={{flexDirection: 'row', position: 'relative'}}>
        <TableRow items={inputs} header={false} columnWidths={props.columnWidths} containerStyle={{...standardHalfPaddingVert, flex: 1}} />
        <View style={{flexDirection: 'row', position: 'absolute', right: 0, ...standardHalfPadding}}>
            {props.sideItem}
        </View>
    </View>
}

export type FillContainerOption = 'both' |'horizontal' | 'vertical' | 'top' | 'bottom'

export type BaseTableProps = {
    columnNames?: TableItemType[],
    containerStyle?: CSSProperties,
    showFilters?: (boolean | ((str: string, itemIndex: number, rawItems: TableItemType[][]) => boolean))[],
    filterPlaceholders?: string[],
    columnWidths?: (number | string)[],
    sideItem?: React.ReactElement,
    forceShowHover?: boolean,
    fillContainer?: FillContainerOption | FillContainerOption[],
    lazyItemsNum?: number,
}

export type TableProps = BaseTableProps & {
    items: TableItemType[][],
    onPressItem?: (index: number) => void,
    onChangeHover?: (rowIndex: number | null) => void,
}

export type TypedTableProps<T> = BaseTableProps & {
    items: T[],
    itemToRow: (item: T, index: number) => TableItemType[],
    onPressItem?: (item: T, index: number) => void,
    onChangeHover?: (state: {hovered: true, item: T, index: number} | {hovered: false}) => void,
}

export const Table: React.FunctionComponent<TableProps> = props => {
    const [filters, setFilters] = React.useState(props.showFilters?.map(sf => sf ? "" as string : null))
    const [currentLazyItemsNum, setCurrentLazyItemsNum] = React.useState(props.lazyItemsNum)

    const filterItem = (itemIndex: number, colIndex: number) => {
        const item = props.items[itemIndex][colIndex]
        if (filters === undefined) return true
        const filter = filters[colIndex]
        const filterProp = props.showFilters && props.showFilters[colIndex]
        if (typeof filterProp === 'function' && typeof filter === 'string') {
            return filterProp(filter, itemIndex, props.items)
        } else {
            const stringPropName: keyof BaseTextProps = 'string'
            return typeof filter === 'string' && filter.length > 0 ?
                typeof item === 'string' && item.length > 0 ?
                    item.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) >= 0 :
                    typeof item === 'object' && item && stringPropName in item.props ?
                        item.props.string.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) >= 0 :
                        true :
                true
        }
    }

    const emptyRow = <TableRow header={false} items={[<ContentText string={LS('empty')} style={{alignSelf: 'center', fontStyle: 'italic', color: palette.borderDefault}} />]} columnWidths={undefined}
        containerStyle={{backgroundColor: palette.textInputFocus}} />
    
    const fillContainerOptions = new Set(props.fillContainer === undefined ? [] :
        typeof props.fillContainer === 'object' ? props.fillContainer : [props.fillContainer])
    const margin = {
        ...standardHalfMargin,
        ...(fillContainerOptions.has('horizontal') || fillContainerOptions.has('both') ? {
            marginLeft: -standardHalfPadding.paddingLeft,
            marginRight: -standardHalfPadding.paddingRight,
        } : null),
        ...(fillContainerOptions.has('top') || fillContainerOptions.has('vertical') || fillContainerOptions.has('both') ? {
            marginTop: -standardHalfPadding.paddingTop,
        } : null),
        ...(fillContainerOptions.has('bottom') || fillContainerOptions.has('vertical') || fillContainerOptions.has('both') ? {
            marginBottom: -standardHalfPadding.paddingBottom,
        } : null),
    }
    const borderRadius = props.fillContainer !== undefined ? 0 : tableBorderRadius
    const setFiltersAndResetLazy = (fs: (string | null)[]) => {
        setCurrentLazyItemsNum(props.lazyItemsNum)
        setFilters(fs)
    }

    const shownItems: React.ReactElement[] = []
    let interruptedForLazy = false
    for (let index = 0; index < props.items.length; ++index) {
        if (currentLazyItemsNum !== undefined && shownItems.length >= currentLazyItemsNum) {
            interruptedForLazy = true
            break
        }

        const itemsRow = props.items[index]
        const curShown = filters === undefined ? true : itemsRow.every((_, colIndex) => filterItem(index, colIndex))
        if (curShown) {
            const comp = <TableRow
                header={false}
                items={itemsRow}
                key={index}
                containerStyle={{backgroundColor: shownItems.length % 2 === 0 ? palette.textInputFocus : palette.white}}
                onPress={props.onPressItem && (() => props.onPressItem && props.onPressItem(index))}
                columnWidths={props.columnWidths}
                onHoverStart={props.onChangeHover ? () => props.onChangeHover && props.onChangeHover(index) : undefined}
                onHoverEnd={props.onChangeHover ? () => props.onChangeHover && props.onChangeHover(null) : undefined}
                forceShowHover={props.forceShowHover} />
            shownItems.push(comp)
        }
    }

    const onPressShowMore = () => currentLazyItemsNum !== undefined && props.lazyItemsNum !== undefined && setCurrentLazyItemsNum(currentLazyItemsNum + props.lazyItemsNum)

    return (
        <View style={{flexDirection: 'column', overflow: 'hidden', ...margin, borderRadius: borderRadius, ...props.containerStyle}}>
            {props.showFilters && <TableFilterRow filters={filters ?? []} placeholders={props.filterPlaceholders} onFiltersChange={fs => setFiltersAndResetLazy(fs)} columnWidths={props.columnWidths} sideItem={props.sideItem} />}
            {props.columnNames && <TableRow header={true} items={props.columnNames.map(n => typeof n === 'string' ? n.toUpperCase() : n)} containerStyle={{backgroundColor: palette.borderFocus}} key={-1} columnWidths={props.columnWidths} />}
            <ScrollView style={{flexDirection: 'column'}}>
                {shownItems.length === 0 ? emptyRow : shownItems}
                {interruptedForLazy && <PressableArea style={{backgroundColor: palette.pageTitlePrev, flexDirection: 'column', alignItems: 'center', height: contentSingleLineHeight * 2}} onPress={onPressShowMore}>
                    <ContentText string={LS('showMore').toLocaleUpperCase()} />
                </PressableArea>}
            </ScrollView>
        </View>
    )
}

export function TableTyped<T>(props: TypedTableProps<T>) {
    return <Table
        {...props}
        items={props.items.map(props.itemToRow)}
        onPressItem={props.onPressItem && (index => props.onPressItem && props.onPressItem(props.items[index], index))}
        onChangeHover={props.onChangeHover && (index => props.onChangeHover && props.onChangeHover(
            index !== null ? {hovered: true, item: props.items[index], index: index} : {hovered: false}
        ))}
    />
}

export type TopItem<TO, TI> = { outer: TO, inners: TI[] }
export type FoldableTableProps<TO, TI> = {
    topItems: TopItem<TO, TI>[],
    topItemToRow: (topItem: TopItem<TO, TI>, index: number) => TableItemType[],
    innerToRow: (inner: TI, index: number, topItem: TopItem<TO, TI>) => TableItemType[],
    onPressInner?: (inner: TI) => void,
    expandedByDefault?: boolean,
    topItemToFilterStrings?: (null | ((topItem: TopItem<TO, TI>) => string))[],
    innerToFilterStrings?: (null | ((inner: TI, topItem: TopItem<TO, TI>) => string))[],
    expandSignStyleFunc?: (topItem: TopItem<TO, TI>) => CSSProperties,
} & BaseTableProps
export type FoldableTableState<TO, TI> = {
    expOrNotTops: StringifySet<TopItem<TO, TI>>,
}

export class FoldableTable<TO, TI> extends React.PureComponent<FoldableTableProps<TO, TI>, FoldableTableState<TO, TI>> {
    constructor(props: FoldableTableProps<TO, TI>) {
        super(props)

        this.state = {
            expOrNotTops: new StringifySet(),
        }
    }

    render() {
        const {props, state} = this

        const expandedOuters = (props.expandedByDefault ?? false) ?
            new StringifySet(props.topItems.filter(top => !state.expOrNotTops.has(top))) :
            state.expOrNotTops

        type FlatItem = {isTop: true, topItem: TopItem<TO, TI>} | {isTop: false, inner: TI, relativeTopItem: TopItem<TO, TI>}
        const flatItems = props.topItems.map(top => ([{isTop: true, topItem: top}] as FlatItem[]).concat(
            expandedOuters.has(top) ?
                top.inners.map(inner => ({isTop: false, inner: inner, relativeTopItem: top})) :
                [])).reduce((acc, v) => acc.concat(v), [])
        const topItemToRowWithSign = (top: TopItem<TO, TI>, rowIndex: number) => {
            const row = props.topItemToRow(top, rowIndex)
            const expandSign = expandedOuters.has(top) ? '－' : '＋'
            return produce(row, newRow => {
                newRow[0] =
                    <View style={{flexDirection: 'row', alignItems: 'baseline'}}>
                        <ContentText string={expandSign} style={{marginRight: 0, ...(props.expandSignStyleFunc && props.expandSignStyleFunc(top))}} />
                        {typeof row[0] === 'string' ?
                            <ContentText string={row[0]} /> :
                            row[0]
                        }
                    </View>
            })
        }
        const makeFilterFunc = (colIndex: number) => (str: string, rowIndex: number, rawItems: TableItemType[][]): boolean => {
            const fi = flatItems[rowIndex]
            const topItemToString = props.topItemToFilterStrings && props.topItemToFilterStrings[colIndex]
            const innerToString = props.innerToFilterStrings && props.innerToFilterStrings[colIndex]
            const elems: TableItemType[] = fi.isTop ?
                [topItemToString ? topItemToString(fi.topItem) : props.topItemToRow(fi.topItem, -1)[colIndex]]
                    .concat(fi.topItem.inners.map(i => innerToString ? innerToString(i, fi.topItem) : props.innerToRow(i, -1, fi.topItem)[colIndex])) :
                [innerToString ? innerToString(fi.inner, fi.relativeTopItem) : props.innerToRow(fi.inner, -1, fi.relativeTopItem)[colIndex]]
            const stringPropName: keyof BaseTextProps = 'string'
            return elems.some(e => typeof e === 'string' ?
                e.toLocaleLowerCase().indexOf(str.toLocaleLowerCase()) >= 0 :
                typeof e === 'object' && e && stringPropName in e.props ?
                    e.props.string.toLocaleLowerCase().indexOf(str.toLocaleLowerCase()) >= 0 :
                    true)
        }
        return <Table
            {...props}
            items={flatItems.map((fi, index) => fi.isTop ? topItemToRowWithSign(fi.topItem, index) : props.innerToRow(fi.inner, index, fi.relativeTopItem))}
            showFilters={props.showFilters?.map((sf, colIndex) => sf === true ? makeFilterFunc(colIndex) : sf)}
            onPressItem={index => {
                const fi = flatItems[index]
                if (fi.isTop) {
                    const newExpOrNot = produce(state.expOrNotTops, expOrNot => {
                        if (expOrNot.has(fi.topItem)) {
                            expOrNot.delete(fi.topItem)
                        } else {
                            expOrNot.add(fi.topItem)
                        }
                    })
                    this.setState({expOrNotTops: newExpOrNot})
                } else {
                    props.onPressInner && props.onPressInner(fi.inner)
                }
            }}
        />
    }
}