import xlsx from 'xlsx'
import { createSelector } from 'reselect'
import { getterGoalTypologyFromId, getterMembershipString, getterPersonFromId, makeIsGoalConsistent, getterVisibleGoalsAffectingPerson, getPersons, getterInfluencesFromCollaboratorId, getterVisibleGoalFromId } from './selectors'
import { GoalInstanceComplete, Person } from './backendTypes'
import { locGoalPhase, locKpiType, locVisibilityPolicy, LS } from './loc/loc'
import { dateToLocalString } from './dateUtils'
import { calcGoalAggregateLikert, calcGoalsAverageScore, flattenArray, makeMap } from './utils'
import React from 'react'
import { RootState } from './state'
import { connect, ConnectedProps } from 'react-redux'
import * as FileSaver from 'file-saver'
import { SmallIcon } from './components/Icon'
import { ContentButton } from './styles'

const makeXlsxBlob = (workbook: xlsx.WorkBook): Blob => {
    const excelBuffer = xlsx.write(workbook, { bookType: "xlsx", type: "array" })
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
    return new Blob([excelBuffer], { type: fileType })
}

export const startBlobDownload = (blob: Blob, fileName: string): void => {
    FileSaver.saveAs(blob, fileName)
}

export const makeCreateGoalsXlsx = createSelector(
    [getterMembershipString, getterGoalTypologyFromId, makeIsGoalConsistent],
    (getMembershipString, getGoalTypologyFromId, isGoalConsistent) => (goals: GoalInstanceComplete[]) => {
        const goalColSelectors: [string, (g: GoalInstanceComplete) => number | string][] = [
            ["Id", g => g.id],
            [LS('title'), g => g.title],
            [LS('description'), g => g.description],
            [LS('parent'), g => g.parentId ?? ''],
            [LS('manager'), g => getMembershipString(g.managerMembership)],
            [LS('assignees'), g => g.assigneePersons.map(m => getMembershipString(m)).join('\n')],
            [LS('activityStart'), g => dateToLocalString(g.activePeriod.start)],
            [LS('activityEnd'), g => dateToLocalString(g.activePeriod.end)],
            [LS('credits'), g => g.weight],
            [LS('typology'), g => getGoalTypologyFromId(g.typologyId).title],
            [LS('visibility'), g => locVisibilityPolicy(g.visibilityPolicyId)],
            [LS('value'), g => calcGoalAggregateLikert(g)],
            [LS('phase'), g => locGoalPhase(g.phaseId)],
            [LS('consistent'), g => isGoalConsistent(g) ? LS('yes') : LS('no')],
            [LS('kpiNumber'), g => g.kpis.length],
            [LS('kpiTitles'), g => g.kpis.map(k => k.title).join('\n')],
            [LS('kpiTypes'), g => g.kpis.map(k => locKpiType(k.numericThresholds !== null, k.withAchievementPercentage)).join('\n')],
        ]

        const goalsJsonData = goals.map(g => Object.fromEntries(goalColSelectors.map(sel => [sel[0], sel[1](g)])))
        const wsg = xlsx.utils.json_to_sheet(goalsJsonData)
        const wb = { Sheets: { goals: wsg }, SheetNames: ["goals"] }
        return makeXlsxBlob(wb)
    }
)

// fatto per esportare punteggi persone in base a dati frontend, per casini Amadori con sample con date sovapposte
export const makeCreatePersonsXlsx = createSelector(
    [getPersons, getterVisibleGoalsAffectingPerson, getterInfluencesFromCollaboratorId, getterVisibleGoalFromId],
    (persons, getVisibleGoalsAffectingPerson, getInfluencesFromCollaboratorId, getVisibleGoalFromId) => () => {
        const goalsByPerson = makeMap(persons.map(p => [p, getVisibleGoalsAffectingPerson(p)]))
        const personColSelectors: [string, (p: Person) => number | string][] = [
            ["Id", p => p.internalId],
            ["FirstName", p => p.firstName],
            ["LastName", p => p.lastName],
            ["NumGoals", p => goalsByPerson.get(p)?.length ?? -1],
            ["AverageScore", p => {
                const goals = goalsByPerson.get(p) ?? []
                const influences = getInfluencesFromCollaboratorId(p.id)
                const inflGoals = influences.map(i => getVisibleGoalFromId(i.goalId)!)
                const inflWeights = new Map(influences.map(i => [i.goalId, i.weight]))
                const goalsAndInflGoals = goals.concat(inflGoals)
                return calcGoalsAverageScore(goalsAndInflGoals, inflWeights)
            }]
        ]

        const personsJsonData = persons.map(p => Object.fromEntries(personColSelectors.map(sel => [sel[0], sel[1](p)])))
        const wsp = xlsx.utils.json_to_sheet(personsJsonData)
        const wb = { Sheets: { persons: wsp }, SheetNames: ["persons"] }
        return makeXlsxBlob(wb)
    }
)

const exportGoalsButtonConnector = connect((s: RootState) => ({
    createGoalsXlsx: makeCreateGoalsXlsx(s),
}))

const ExportGoalsButtonComponent: React.FunctionComponent<{goals: GoalInstanceComplete[]} & ConnectedProps<typeof exportGoalsButtonConnector>> = props => {
    const onPress = () => {
        const blob = props.createGoalsXlsx(props.goals)
        startBlobDownload(blob, "goals.xlsx")
    }
    return <ContentButton icon={<SmallIcon name='excel' />} onPress={onPress} />
}
export const ExportGoalsButton = exportGoalsButtonConnector(ExportGoalsButtonComponent)