import { put as rawPut, call, select, takeLatest, takeEvery } from 'redux-saga/effects'
import { ActionType, AppAction, AuthenticateUserResponse, ChooseDbInstance, NavigateTo, NavigationPush, NavigationPop, CreateGoal, TryOpenGoalComplete, DeleteGoal, AddSamples, CreateInfluences, EditGoal, AffectInfluences, CreateDummyGoals, CreateSharedCollection, EditSharedCollection, DeleteSharedCollection, ExtendedEditGoal, ChangeGoalPhase, MoveGoalsAndInfluencesWithPersons, SetAllMissingParentsToRoot, ExportXls, DeleteSample, ExportXlsExpanded } from './actions'
import { ktaskBackendUrl, ktaskClientUrl } from './config'
import { Person, Team, Cycle, GoalTier, GoalTypology, GetUserDataValue, SampleCollectionInstance, SamplerGroup, ResultCode, GoalInstanceComplete, InfluenceInstance, InfluenceRef, ChangeGoalPhaseArgs, PublicDbInstanceData, GetDbInstancesCmd } from './backendTypes'
import { CommonDataState, RootState } from './state'
import { getPlatform } from './baseUtils'
import { mkRec, InternalResultCode, IdType, } from './utils'
import ConcreteCommand from './concreteCommand'
import { Map } from 'immutable'
import { requestToken, tryGetTokenAndStateFromLocation, logout } from './web/oidcClient'
import UrlManager from './web/UrlManager'
import { getRouteFromUrl, getCurrentUrl, ScreenRouteId } from './routing'
import Cookie from 'js-cookie'
import moment from 'moment'
import { ViewGoalScreenExternalProps } from './screens/ViewGoalScreen'

const put = (action: AppAction) => rawPut(action) // per averlo tipizzato a AppAction

const tokenCookieName = 'token'

const makeInit = (body: string) => {
    return {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Content-Type',
            'Access-Control-Allow-Methods': 'POST',
            'Content-Type': 'application/json',
        },
        body: body,
    }
}

const makeCommandsInit = (token: string, dbInstanceId: string, commands: Array<ConcreteCommand>) => {
    return makeInit(JSON.stringify({
        token: token,
        dbInstanceId: dbInstanceId,
        commands: commands
    }))
}

const initializeSaga = function*() {
    const token = yield select(s => s.token)
    if (!token) {
        if (getPlatform() === 'web') {
            const cookieToken = Cookie.get(tokenCookieName)
            if (cookieToken && cookieToken !== "") {
                yield put({type: ActionType.SET_TOKEN, token: cookieToken})
                /*yield fetchCommonDataSaga()
                const route = yield call(getCurrentUrl)
                yield put({type: ActionType.NAVIGATE_TO, stackItem: {routeId: getRouteFromUrl(route), props: null}})*/
                yield put({type: ActionType.CHOOSE_DB_INSTANCE})
            } else {
                const {token, state} = tryGetTokenAndStateFromLocation()
                if (token && state) {
                    if (getPlatform() == 'web') {
                        // pulisce l'url da token e state
                        window.history.replaceState(null, '', window.location.origin)
                    }
                    yield put({type: ActionType.AUTHENTICATE_USER_RESPONSE, success: true, token: token, state: state})
                } else {
                    yield put({type: ActionType.AUTHENTICATE_USER_REQUEST})
                }
            }
        } else {
            throw new Error("unsupported platform in initializeSaga")
        }
    } else {
        console.log("initializeSaga: token found")
    }
}

const resetSaga = function*() {
    // yield put({type: ActionType.RESET_STATE}) // commentato perchè non si sa perchè non fa tornare al login di auth0
    yield put({type: ActionType.INITIALIZE})
}

const logoutSaga = function*() {
    console.log("logout saga")
    if (getPlatform() === 'web') {
        Cookie.remove(tokenCookieName)
    }
    yield call(logout)
    yield resetSaga()
}

const authenticateUserSaga = function*() {
    if (getPlatform() === 'web') {
        yield call(requestToken)
    } else {
        throw new Error("unsupported platform in authenticateUserSaga")
    }
}

const authenticationEndedSaga = function*(action: AuthenticateUserResponse) {
    if (action.success) {
        if (getPlatform() === 'web') {
            Cookie.set(tokenCookieName, action.token)
        }
        yield put({type: ActionType.SET_TOKEN, token: action.token})
        yield put({type: ActionType.CHOOSE_DB_INSTANCE})
    } else {
        yield put({type: ActionType.CRITICAL_ERROR, data: {internalCode: InternalResultCode.AuthenticationFailed}})
    }
}

const fetchChecked = function*(reqInit: ReturnType<typeof makeInit>, url: string) {
    console.log("fetchChecked request: " + JSON.stringify(reqInit))
    let resp: any = undefined
    try {
        resp = yield call(fetch, url, reqInit)
    } catch (e) {
        console.log("fetchChecked exception: " + JSON.stringify(e))
        yield put({type: ActionType.CRITICAL_ERROR, data: {internalCode: InternalResultCode.FetchFailed}})
        return undefined
    }
    //const resp : any = fetch(klikeServerUrl + "api/exec", reqInit).then(res => "fetch result: " + console.log(JSON.stringify(res)))
    if (!resp) return null
    const respString = yield call([resp, 'text'])
    console.log("fetchChecked response: " + respString)

    const dateTimeReviver = (_: string, v: any) => {
        if (v && typeof v === 'object' && v["@type"] === "DateTimeOffset") {
            const date = moment(v.value).toDate()
            return date
        } else {
            return v;
        }
    }
    let json: any = undefined
    try {
        json = JSON.parse(respString, dateTimeReviver)
    } catch (e) {
        // in caso di errore di parse non dà errore critico (di solito si lascia la schermata precedente per poter capire quali dati non sono piaciuti)
        return undefined
    }

    return json
}

const fetchCommands = function*(commands: Array<ConcreteCommand>) {
    const token = yield select(s => s.token)
    const dbInstanceId = yield select(s => s.selectedDbInstanceId)
    const reqInit = makeCommandsInit(token, dbInstanceId, commands)
    let json = yield fetchChecked(reqInit, ktaskBackendUrl + "api/exec")
    if (!(json?.result && json?.commands)) { // scarta il json se non è davvero una risposta
        json = undefined
    }
    return json
}

const showResponseError = function*(resp: any, handleAsCritical: boolean) {
    if (resp) {
        if (resp.success) {
            // non fa niente
        } else {
            if (resp.result.code === ResultCode.someCommandFailed) {
                const firstFailedCmd = resp.commands.find((cmd: any) => !cmd.result.success)
                if (handleAsCritical) {
                    yield put({type: ActionType.CRITICAL_ERROR, data: {internalCode: InternalResultCode.BackendReturnedFailure, backendCode: firstFailedCmd.result.code}})
                } else {
                    alert("backend error: " + firstFailedCmd.result.code + ", " + JSON.stringify(firstFailedCmd.result.details))
                }
            } else {
                if (handleAsCritical) {
                    yield put({type: ActionType.CRITICAL_ERROR, data: {internalCode: InternalResultCode.BackendReturnedFailure, backendCode: resp.result.code}})
                } else {
                    alert("backend error: " + resp.result.code + ", " + JSON.stringify(resp.result.details))
                }
            }
        }
    } else {
        if (handleAsCritical) {
            yield put({type: ActionType.CRITICAL_ERROR, data: {internalCode: InternalResultCode.FetchFailed}})
        } else {
            alert("backend connection error or bad request (maybe wrong json body)")
        }
    }
    yield null // tanto per lasciarla come generatore ma evitare il warning che non ha yield
}

const fetchCommandsAndHandleError = function*(commands: Array<ConcreteCommand>, handleAsCritical: boolean = false) {
    const resp = yield fetchCommands(commands)
    if (resp?.result.success) {
        return resp
    } else {
        yield showResponseError(resp, handleAsCritical)
        return null
    }
}

const chooseDbInstanceSaga = function*(action: ChooseDbInstance) {
    const token = yield select(s => s.token)
    if (!token) throw new Error("token cannot be null")
    const respObj: any = yield fetchCommandsAndHandleError([{ cmd: 'getDbInstances' }], true)
    if (respObj) {
        const cmd: GetDbInstancesCmd = respObj.commands[0]
        if (!cmd.result.success) {
            yield put({type: ActionType.CRITICAL_ERROR, data: {internalCode: InternalResultCode.BackendReturnedFailure, backendCode: cmd.result.code}})
        } else {
            const dbInstances = cmd.result.value
            yield put({type: ActionType.SET_DB_INSTANCES, dbInstances: dbInstances})
            if (dbInstances.length == 1) {
                // se c'è un'istanza sola, usa direttamente quella
                yield put({type: ActionType.SET_SELECTED_DB_INSTANCE_ID, dbInstanceId: dbInstances[0].id})
                yield put({type: ActionType.FETCH_COMMON_DATA_REQUEST})
            }
        }
    }
}

const navigateToSaga = function*(action: NavigateTo) {
    //getNavigator().immediatelyResetRouteStack([{routeId: action.stackItem.routeId, sceneConfigType: NavigatorSceneConfigType.Fade}])
    yield call(UrlManager.navigatedTo, action.stackItem.routeId)
}

const navigationPushSaga = function*(action: NavigationPush) {
    //getNavigator().push({routeId: action.stackItem.routeId, sceneConfigType: NavigatorSceneConfigType.Fade})
    //UrlManager.navigatedTo(action.stackItem.routeId)
}

const navigationPopSaga = function*(_: NavigationPop) {
    //getNavigator().pop()
    //UrlManager.navigatedTo(action.stackItem.routeId)
}

const fetchCommonDataSaga = function*() {
    const reqCmds: ConcreteCommand[] = [
        { cmd: 'getCycle' },
        { cmd: 'getPersons' },
        { cmd: 'getTeams' },
        { cmd: 'getGoalTiers' },
        { cmd: 'getGoalTypologies' },
        { cmd: 'getUserData' },
        { cmd: 'getVisibleGoalsData' },
        { cmd: 'getSharedSampleCollections' },
        { cmd: 'getSamplerGroups' },
        { cmd: 'getInfluences' },
    ]
    const resp: any = yield fetchCommands(reqCmds)
    if (resp && resp.result.success) {
        const values = resp.commands.map((c: any) => c.result.value)
        const cycle: Cycle = values[0]
        const persons: Person[] = values[1]
        const teams: Team[] = values[2]
        const goalTiers: GoalTier[] = values[3]
        const goalTypologies: GoalTypology[] = values[4]
        const userData: GetUserDataValue = values[5]
        const visGoalsComplete: GoalInstanceComplete[] = values[6]
        const sharedCollections: SampleCollectionInstance[] = values[7]
        const samplerGroups: SamplerGroup[] = values[8]
        const influences: InfluenceInstance[] = values[9]

        const byId = <T extends {id: IdType}>(xs: Array<T>): Map<IdType, T> => Map(xs.map(x => [x.id, x]))
        const data: CommonDataState = mkRec({
            cycle: cycle,
            personsById: byId(persons),
            teamsById: byId(teams),
            goalTiersById: byId(goalTiers),
            goalTypologiesById: byId(goalTypologies),
            userData: userData,
            visibleGoalsById: byId(visGoalsComplete),
            sharedSampleCollectionsById: byId(sharedCollections),
            samplerGroupsById: byId(samplerGroups),
            visibleInfluences: influences,
        })
        yield put({type: ActionType.FETCH_COMMON_DATA_RESPONSE, payload: data})
    } else {
        if (resp) {
            yield put({type: ActionType.CRITICAL_ERROR, data: {internalCode: InternalResultCode.BackendReturnedFailure, backendCode: resp.result.code}})
        } else {
            yield put({type: ActionType.CRITICAL_ERROR, data: {internalCode: InternalResultCode.FetchFailed}})
        }
    }
}

const pushLoadingScreen = function*() {
    yield put({type: ActionType.NAVIGATION_PUSH, stackItem: {routeId: ScreenRouteId.Loading, props: null}})
}

const createGoalSaga = function*(action: CreateGoal) {
    yield pushLoadingScreen()

    const reqCmds = [
        { cmd: 'createGoal', args: action.goalDef },
    ]
    const resp = yield fetchCommandsAndHandleError(reqCmds)

    if (resp) {
        yield fetchCommonDataSaga()
        yield put({type: ActionType.NAVIGATE_TO, stackItem: {routeId: ScreenRouteId.Goals, props: null}})
    } else {
        yield put({type: ActionType.NAVIGATION_POP})
    }
}

const tryOpenGoalCompleteSaga = function*(action: TryOpenGoalComplete) {
    const screenProps: ViewGoalScreenExternalProps = {goalId: action.goalId}
    const navAction: AppAction = {
        type: ActionType.NAVIGATION_PUSH,
        stackItem: {routeId: ScreenRouteId.ViewGoal, props: screenProps},
    }
    yield put(navAction)
}

const deleteGoalSaga = function*(action: DeleteGoal) {
    yield pushLoadingScreen()

    const reqCmds = [
        { cmd: 'deleteGoal', args: { goalId: action.goalId } },
    ]
    const resp = yield fetchCommandsAndHandleError(reqCmds)

    yield put({type: ActionType.NAVIGATION_POP})

    if (resp) {
        yield put({type: ActionType.FETCH_COMMON_DATA_REQUEST})
        yield put({type: ActionType.NAVIGATE_TO, stackItem: {routeId: ScreenRouteId.Goals, props: null}})
    }
}

const addSamplesSaga = function*(action: AddSamples) {
    yield pushLoadingScreen()
    
    const reqCmds = [{ cmd: 'createSamples', args: action.argsEntries}]
    const resp = yield fetchCommandsAndHandleError(reqCmds)

    if (resp) {
        yield fetchCommonDataSaga()
        yield put({type: ActionType.NAVIGATION_POP})
        yield put({type: ActionType.NAVIGATION_POP})
    } else {
        yield put({type: ActionType.NAVIGATION_POP})
    }
}

const deleteSampleSaga = function*(action: DeleteSample) {
    yield pushLoadingScreen()
    
    const reqCmds = [{ cmd: 'deleteSample', args: action.sampleId}]
    const resp = yield fetchCommandsAndHandleError(reqCmds)

    if (resp) {
        yield fetchCommonDataSaga()
        yield put({type: ActionType.NAVIGATION_POP})
    } else {
        yield put({type: ActionType.NAVIGATION_POP})
    }
}

const createInfluencesSaga = function*(action: CreateInfluences) {
    yield pushLoadingScreen()

    const reqCmds = action.influences.map(infl => ({
        cmd: 'createInfluence',
        args: infl,
    }))
    const resp = yield fetchCommandsAndHandleError(reqCmds)

    if (resp) {
        yield fetchCommonDataSaga()
        yield put({type: ActionType.NAVIGATION_POP})
        yield put({type: ActionType.NAVIGATION_POP})
    } else {
        yield put({type: ActionType.NAVIGATION_POP})
    }
}

const affectInfluencesSaga = function*(action: AffectInfluences) {
    yield pushLoadingScreen()

    const deleteCmds = action.toDelete.map(infl => {
        const ref: InfluenceRef = {
            goalId: infl.goalId,
            collaboratorMembership: infl.collaboratorMembership,
            managerMembership: infl.managerMembership,
        }
        return {
            cmd: 'deleteInfluence',
            args: ref,
        }
    })
    const createCmds = action.toCreate.map(infl => ({
        cmd: 'createInfluence',
        args: infl,
    }))
    const editCmds = action.toEdit.map(infl => ({
        cmd: 'editInfluence',
        args: infl,
    }))

    const allCmds = deleteCmds.concat(createCmds).concat(editCmds)
    const resp = yield fetchCommandsAndHandleError(allCmds)

    if (resp) {
        yield fetchCommonDataSaga()
        yield put({type: ActionType.NAVIGATION_POP})
    }

    yield put({type: ActionType.NAVIGATION_POP})
}

const editGoalSaga = function*(action: EditGoal) {
    yield pushLoadingScreen()

    const reqCmds = [{
        cmd: 'editGoal',
        args: action.goalDef,
    }]
    const resp = yield fetchCommandsAndHandleError(reqCmds)
    
    if (resp) {
        yield fetchCommonDataSaga()
        yield put({type: ActionType.NAVIGATION_POP})
    }
    yield put({type: ActionType.NAVIGATION_POP})
}

const extendedEditGoalSaga = function*(action: ExtendedEditGoal) {
    yield pushLoadingScreen()

    const reqCmds = [{
        cmd: 'editGoal',
        args: action.goalDef,
    }, {
        cmd: 'reparentGoal',
        args: action.reparentArgs,
    }, {
        cmd: 'reassignGoal',
        args: action.reassignArgs,
    }]
    const resp = yield fetchCommandsAndHandleError(reqCmds)
    
    if (resp) {
        yield fetchCommonDataSaga()
        yield put({type: ActionType.NAVIGATION_POP})
    }
    yield put({type: ActionType.NAVIGATION_POP})
}

const changeGoalPhaseSaga = function*(action: ChangeGoalPhase) {
    yield pushLoadingScreen()

    const reqCmds = [{
        cmd: 'changeGoalPhase',
        args: action.args,
    }]
    const resp = yield fetchCommandsAndHandleError(reqCmds)

    if (resp) {
        yield fetchCommonDataSaga()
        yield put({type: ActionType.NAVIGATION_POP})
    }
    yield put({type: ActionType.NAVIGATION_POP})
}

const createSharedCollectionSaga = function*(action: CreateSharedCollection) {
    yield pushLoadingScreen()

    const reqCmds = [{
        cmd: 'createSharedCollection',
        args: action.collectionDef,
    }]
    const resp = yield fetchCommandsAndHandleError(reqCmds)

    if (resp) {
        yield fetchCommonDataSaga()
        yield put({type: ActionType.NAVIGATION_POP})
    }
    yield put({type: ActionType.NAVIGATION_POP})
}

const editSharedCollectionSaga = function*(action: EditSharedCollection) {
    yield pushLoadingScreen()

    const reqCmds = [{
        cmd: 'editSharedCollection',
        args: action.collectionDef,
    }]
    const resp = yield fetchCommandsAndHandleError(reqCmds)

    if (resp) {
        yield fetchCommonDataSaga()
        yield put({type: ActionType.NAVIGATION_POP})
    }
    yield put({type: ActionType.NAVIGATION_POP})
}

const deleteSharedCollectionSaga = function*(action: DeleteSharedCollection) {
    yield pushLoadingScreen()

    const reqCmds = [{
        cmd: 'deleteSharedCollection',
        args: action.collectionId,
    }]
    const resp = yield fetchCommandsAndHandleError(reqCmds)

    if (resp) {
        // toglie ViewSharedCollectionScreen prima di fare il fetch, perchè ha dentro l'id che non esiste più
        yield put({type: ActionType.NAVIGATION_POP})
        yield put({type: ActionType.NAVIGATION_POP})
        yield pushLoadingScreen()
        yield fetchCommonDataSaga()
    }
    yield put({type: ActionType.NAVIGATION_POP})
}

const moveGoalsAndInfluencesWithPersonsSaga = function*(action: MoveGoalsAndInfluencesWithPersons) {
    yield pushLoadingScreen()

    const reqCmds = [{
        cmd: 'moveGoalsAndInfluencesWithPersons',
        args: action.time,
    }]
    const resp = yield fetchCommandsAndHandleError(reqCmds)

    if (resp) {
        yield fetchCommonDataSaga()
    }

    yield put({type: ActionType.NAVIGATION_POP})
}

const setAllMissingParentsToRootSaga = function*(action: SetAllMissingParentsToRoot) {
    yield pushLoadingScreen()

    const reqCmds = [{
        cmd: 'setAllMissingParentsToRoot',
        args: action.rootId,
    }]
    const resp = yield fetchCommandsAndHandleError(reqCmds)

    if (resp) {
        yield fetchCommonDataSaga()
    }

    yield put({type: ActionType.NAVIGATION_POP})
}

const createDummyGoalsSaga = function*(action: CreateDummyGoals) {
    yield pushLoadingScreen()

    const reqCmds = [{
        cmd: 'createDummyGoals',
    }]
    const resp = yield fetchCommandsAndHandleError(reqCmds)

    if (resp) {
        yield fetchCommonDataSaga()
    }

    yield put({type: ActionType.NAVIGATION_POP})
}

const exportXls = function*(action: ExportXls) {
    yield pushLoadingScreen()

    const url = ktaskBackendUrl +
        "api/exportGoals?token=" + (yield select((s: RootState) => s.token)) +
        "&dbInstanceId=" + (yield select(s => s.selectedDbInstanceId))
    window.location.href = url

    yield put({type: ActionType.NAVIGATION_POP})
}
const exportXlsExpanded = function*(action: ExportXlsExpanded) {
    yield pushLoadingScreen()

    const url = ktaskBackendUrl +
        "api/exportGoalsExpanded?token=" + (yield select((s: RootState) => s.token)) +
        "&dbInstanceId=" + (yield select(s => s.selectedDbInstanceId))
    window.location.href = url

    yield put({type: ActionType.NAVIGATION_POP})
}

export const rootSaga = function*() {
    yield takeLatest(ActionType.INITIALIZE, initializeSaga)
    yield takeLatest(ActionType.LOGOUT, logoutSaga)
    yield takeLatest(ActionType.AUTHENTICATE_USER_REQUEST, authenticateUserSaga)
    yield takeLatest(ActionType.AUTHENTICATE_USER_RESPONSE, authenticationEndedSaga)
    yield takeLatest(ActionType.CHOOSE_DB_INSTANCE, chooseDbInstanceSaga)
    yield takeLatest(ActionType.FETCH_COMMON_DATA_REQUEST, fetchCommonDataSaga)
    yield takeEvery(ActionType.NAVIGATE_TO, navigateToSaga)
    yield takeEvery(ActionType.NAVIGATION_PUSH, navigationPushSaga)
    yield takeEvery(ActionType.NAVIGATION_POP, navigationPopSaga)
    yield takeLatest(ActionType.CREATE_GOAL, createGoalSaga)
    yield takeLatest(ActionType.TRY_OPEN_GOAL_COMPLETE, tryOpenGoalCompleteSaga)
    yield takeLatest(ActionType.DELETE_GOAL, deleteGoalSaga)
    yield takeLatest(ActionType.ADD_SAMPLES, addSamplesSaga)
    yield takeLatest(ActionType.DELETE_SAMPLE, deleteSampleSaga)
    yield takeLatest(ActionType.CREATE_INFLUENCES, createInfluencesSaga)
    yield takeLatest(ActionType.AFFECT_INFLUENCES, affectInfluencesSaga)
    yield takeLatest(ActionType.EDIT_GOAL, editGoalSaga)
    yield takeLatest(ActionType.EXTENDED_EDIT_GOAL, extendedEditGoalSaga)
    yield takeLatest(ActionType.CHANGE_GOAL_PHASE, changeGoalPhaseSaga)
    yield takeLatest(ActionType.CREATE_SHARED_COLLECTION, createSharedCollectionSaga)
    yield takeLatest(ActionType.EDIT_SHARED_COLLECTION, editSharedCollectionSaga)
    yield takeLatest(ActionType.DELETE_SHARED_COLLECTION, deleteSharedCollectionSaga)
    yield takeLatest(ActionType.MOVE_GOALS_AND_INFLUENCES_WITH_PERSONS, moveGoalsAndInfluencesWithPersonsSaga)
    yield takeLatest(ActionType.SET_ALL_MISSING_PARENTS_TO_ROOT, setAllMissingParentsToRootSaga)
    yield takeLatest(ActionType.CREATE_DUMMY_GOALS, createDummyGoalsSaga)
    yield takeLatest(ActionType.EXPORT_XLS, exportXls)
    yield takeLatest(ActionType.EXPORT_XLS_EXPANDED, exportXlsExpanded)
}