import React from 'react'
import { connect, ConnectedProps} from 'react-redux'
import { ActionType, NavigationPush } from '../actions'
import { PlatformRoleId } from '../backendTypes'
import ScreenWrapper from '../components/ScreenWrapper'
import { TableTyped } from '../components/Table'
import { LS } from '../loc/loc'
import { ScreenRouteId } from '../routing'
import { getSharedSampleCollections, getUserPerson } from '../selectors'
import { RootState } from '../state'
import { Card, ContentButton, View } from '../styles'
import { dateToLocalString, getSampleValueAsString, IdType, personHasPlatformRole } from '../utils'
import { CreateSharedCollectionScreenExternalProps } from './CreateSharedCollectionScreen'
import { UpdateCollectionsScreenExternalProps } from './UpdateCollectionsScreen'
import { ViewSharedCollectionScreenExternalProps } from './ViewSharedCollectionScreen'

const connector = connect((s: RootState) =>({
    sharedSampleCollections: getSharedSampleCollections(s),
    userPerson: getUserPerson(s),
}))

type ExternalProps = {
}

type ComponentProps = ExternalProps & ConnectedProps<typeof connector>

const SharedCollectionsScreenComponent: React.FunctionComponent<ComponentProps> = props => {
    const privileged = personHasPlatformRole(props.userPerson, PlatformRoleId.Privileged)

    const updateScreenProps: UpdateCollectionsScreenExternalProps = {
        goalId: null,
    }
    const updateNavAction: NavigationPush = {
        type: ActionType.NAVIGATION_PUSH,
        stackItem: {
            routeId: ScreenRouteId.UpdateCollections,
            props: updateScreenProps,
        },
    }

    const createSharedCollScreenProps: CreateSharedCollectionScreenExternalProps = {
        editedCollectionId: null,
    }
    const createNavAction: NavigationPush = {
        type: ActionType.NAVIGATION_PUSH,
        stackItem: {
            routeId: ScreenRouteId.CreateSharedCollection,
            props: createSharedCollScreenProps,
        }
    }

    const makeDetailsNavAction = (collId: IdType) => {
        const screenProps: ViewSharedCollectionScreenExternalProps = {
            collectionId: collId,
        }
        const detailsNavAction: NavigationPush = {
            type: ActionType.NAVIGATION_PUSH,
            stackItem: {
                routeId: ScreenRouteId.ViewSharedCollection,
                props: screenProps,
            }
        }
        return detailsNavAction
    }

    const headerItem = <>
        {privileged && <ContentButton label={LS('create')} onPress={() => props.dispatch(createNavAction)} />}
        <ContentButton label={LS('updateCollections')} onPress={() => props.dispatch(updateNavAction)} />
    </>
    return <ScreenWrapper headerItem={headerItem}>
        <View>
            <Card containerStyle={{padding: 0}}>
                <TableTyped items={props.sharedSampleCollections} itemToRow={coll => {
                    const lastSample = coll.samples.length > 0 ? coll.samples[coll.samples.length - 1] : null
                    return [
                        coll.name,
                        lastSample ? getSampleValueAsString(coll, lastSample.value) : '-',
                        lastSample ? dateToLocalString(lastSample.date, '@d') : '-',
                    ]
                }} columnNames={[LS('name'), LS('lastValue'), LS('date')]} columnWidths={[1, 1, 0.25]}
                showFilters={[true, false, false]}
                containerStyle={{margin: 0}}
                onPressItem={coll => props.dispatch(makeDetailsNavAction(coll.id))} />
            </Card>
        </View>
    </ScreenWrapper>
}
export default connector(SharedCollectionsScreenComponent)