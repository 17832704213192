import React, { CSSProperties } from 'react'
import { ContentTextInput } from '../styles'
import { numberToLocString, stringToNumber } from '../utils'

type NumberTextInputProps = {
    placeholder?: string,
    style?: CSSProperties,
    numberValue: number | null,
    onChangeNumber: (number: number | null) => void,
    valid?: boolean,
    editable?: boolean,
}

export class NumberTextInput extends React.PureComponent<NumberTextInputProps, {stringValue: string}> {
    constructor(props: NumberTextInputProps) {
        super(props)

        this.state = {
            stringValue: props.numberValue === null ? '' : numberToLocString(props.numberValue),
        }
    }

    private onChange(text: string) {
        this.setState({stringValue: text})
        const number = stringToNumber(text)
        this.props.onChangeNumber(number)
    }

    render() {
        const {props, state} = this
        return <ContentTextInput
            placeholder={props.placeholder}
            style={props.style}
            value={state.stringValue}
            onChangeText={this.onChange.bind(this)}
            valid={props.valid}
            editable={props.editable}
        />
    }
}

export default NumberTextInput