import React from 'react'
import {connect, ConnectedProps} from 'react-redux'
import { Dispatch } from 'redux'
import { AppAction } from '../actions'
import { PlatformRoleId } from '../backendTypes'
import { LS } from '../loc/loc'
import { getterMembershipsManagedByPerson, getUserPerson, makeGetManagerMembershipsOfPerson } from '../selectors'
import {RootState} from '../state'
import { personHasPlatformRole } from '../utils'
import { GoalUserMode } from './CreateGoalScreen'
import ListSelectorScreen from './ListSelectorScreen'

const connector = connect((s: RootState) => ({
    userPerson: getUserPerson(s),
    getManagerMembershipsOfPerson: makeGetManagerMembershipsOfPerson(s),
    getMembershipsManagedByPerson: getterMembershipsManagedByPerson(s),
}), (dispatch: Dispatch<AppAction>) => ({
    dispatch,
}))

export type SelectUserModeExternalProps = {
    onConfirm: (userMode: GoalUserMode) => void,
}

const SelectUserModeScreen: React.FunctionComponent<SelectUserModeExternalProps & ConnectedProps<typeof connector>> = props => {
    const privileged = personHasPlatformRole(props.userPerson, PlatformRoleId.Privileged)
    const collaborators = props.getMembershipsManagedByPerson(props.userPerson, false)
    const managers = props.getManagerMembershipsOfPerson(props.userPerson, false)
    const modes: GoalUserMode[] =
        (privileged ? [GoalUserMode.Privileged] : [])
        .concat(collaborators.length > 0 ? [GoalUserMode.Manager] : [])
        .concat(managers.length > 0 ? [GoalUserMode.Assignee] : [])
    return <ListSelectorScreen
        columnNames={[LS('mode')]}
        items={modes.map(v =>
            [v === GoalUserMode.Privileged ? LS('userModePrivilegedDescr')
            : v === GoalUserMode.Manager ? LS('userModeManagerDescr')
            : v === GoalUserMode.Assignee ? LS('userModeAssigneeDescr')
            : ''])}
        multiSelection={false}
        onConfirm={indices => props.onConfirm(modes[indices[0]])}
    />
}

export default connector(SelectUserModeScreen)