import moment from 'moment'
import { LS } from './loc/loc'

export const dateFormatString = LS('dateFormat')
export const timeFormatString = "HH:mm"
export const dateToLocalString = (date: Date, format: string = '@d @t'): string => {
    const rawFormat = format.replace('@d', dateFormatString).replace('@t', timeFormatString)
    return moment(date).local().format(rawFormat)
}

export const getCurrentDateTime = () => new Date()

export const dateDifferenceSeconds = (a: Date, b: Date): number => {
    const aM: moment.Moment = moment(a)
    const bM: moment.Moment = moment(b)
    const duration = moment.duration(aM.diff(bM))
    return duration.asSeconds()
}