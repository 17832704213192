import React from 'react'
import {connect, ConnectedProps} from 'react-redux'
import {RootState} from '../state'
import { TeamMembership, KpiAggregationPolicyId, PlatformRoleId, GoalInstanceComplete, GoalInstanceOverview, GoalPhaseId } from '../backendTypes'
import { ContentLabel, Card, ContentButton, normalFontSize, ContentText, View, ContentSeparator, WrapperLabel, VerticalContentSeparator, ScrollView } from '../styles'
import ScreenWrapper from '../components/ScreenWrapper'
import { getUserPerson, getterPersonFromId, getterTeamFromId, getterMembershipString, getterMembershipsManagedByMembership, getterGoalTypologiesUsableByPersonId, getAllGoalTypologies, getCycle, getterVisibleGoalsAffectingPerson, getterPossibleOtherParentsForGoalByManager, getVisibleGoalIdsByParent, getterGoalTypologyFromId, getterVisibleGoalFromId, makeGetGoalConsistency, getRootMemberships, makeIsGoalEffective, makeCanPersonEditGoal, makeCanPersonDeleteGoal, makeGetPossibleParentGoalsForManager, getAllPossibleParentGoalsForPrivileged } from '../selectors'
import { makeShowListSelectorScreen, makeShowListSelectorScreenTyped, IdType, personHasPlatformRole, dateToLocalString, showMessageBox, getSampleValueAsString, calcGoalAggregateLikert, userCanUpdateKpi, getKpiTargetString, getKpiLastSample, membershipsEqual, numberToLocString } from '../utils'
import { NavigationPush, ActionType, DeleteGoal, AppAction } from '../actions'
import { ScreenRouteId } from '../routing'
import { TableItemType, Table, TableTyped } from '../components/Table'
import { LS, locAggregationPolicy, locVisibilityPolicy, LSl } from '../loc/loc'
import { UpdateCollectionsScreenExternalProps } from './UpdateCollectionsScreen'
import LikertProgressBar from '../components/LikertProgressBar'
import { ViewKpiScreenExternalProps } from './ViewKpiScreen'
import { CreateGoalScreenExternalProps, GoalUserMode } from './CreateGoalScreen'
import { customSettings } from '../config'
import { Dispatch } from 'redux'
import { SelectUserModeExternalProps as SelectUserModeScreenExternalProps } from './SelectUserModeScreen'
import { SmallIcon, WarningSmallIcon } from '../components/Icon'

const connector = connect((s: RootState) => ({
    userPerson: getUserPerson(s),
    getPersonFromId: getterPersonFromId(s),
    getTeamFromId: getterTeamFromId(s),
    getVisibleGoalFromId: getterVisibleGoalFromId(s),
    getMembershipString: getterMembershipString(s),
    getMembershipsManagedBy: getterMembershipsManagedByMembership(s),
    rootMemberships: getRootMemberships(s),
    getTypologiesUsableByPersonId: getterGoalTypologiesUsableByPersonId(s),
    getGoalTypologyFromId: getterGoalTypologyFromId(s),
    allGoalTypologies: getAllGoalTypologies(s),
    cycle: getCycle(s),
    getVisibleGoalsAffectingPerson: getterVisibleGoalsAffectingPerson(s),
    getPossibleOtherParentsForGoalByManager: getterPossibleOtherParentsForGoalByManager(s),
    getPossibleParentGoalsForManager: makeGetPossibleParentGoalsForManager(s),
    allPossibleParents: getAllPossibleParentGoalsForPrivileged(s),
    visibleGoalIdsByParent: getVisibleGoalIdsByParent(s),
    getGoalConsistency: makeGetGoalConsistency(s),
    canPersonEditGoal: makeCanPersonEditGoal(s),
    canPersonDeleteGoal: makeCanPersonDeleteGoal(s),
}), (dispatch: Dispatch<AppAction>) => ({
    dispatch,
    showListSelectorScreen: makeShowListSelectorScreen(dispatch),
    showListSelectorScreenTyped: makeShowListSelectorScreenTyped(dispatch),
}))

export type ViewGoalScreenExternalProps = {
    goalId: IdType,
}

type ComponentProps = ViewGoalScreenExternalProps & ConnectedProps<typeof connector>

class ViewGoalScreen extends React.PureComponent<ComponentProps> {
    render() {
        const {props} = this

        const goalData = props.getVisibleGoalFromId(props.goalId)

        if (goalData === null) return "goal id not found " + props.goalId

        //const isInstanceComplete = 'kpis' in goalData // sarebbe da usare al posto dei vari ""'kpis' in goalData"
        const privileged = personHasPlatformRole(props.userPerson, PlatformRoleId.Privileged)
        const canEdit = props.canPersonEditGoal(props.userPerson, goalData)
        const canDelete = props.canPersonDeleteGoal(props.userPerson, goalData)
        const consistency = props.getGoalConsistency(goalData)
        
        const onPressEditGoal = () => {
            const editScreenProps: CreateGoalScreenExternalProps = {
                userMode: privileged ? GoalUserMode.Privileged
                    : goalData.managerMembership?.personId === props.userPerson.id ? GoalUserMode.Manager
                    : GoalUserMode.Assignee,
                dataMode: {type: 'edit', data: goalData},
            }
            const editNavAction: AppAction = {
                type: ActionType.NAVIGATION_PUSH,
                stackItem: {
                    routeId: ScreenRouteId.CreateGoal,
                    title: LS('editGoal'),
                    props: editScreenProps,
                }
            }
            props.dispatch(editNavAction)
        }

        const onPressDeleteGoal = () => {
            if (!goalData) throw new Error()
            const childrenIds = props.visibleGoalIdsByParent.get(goalData.id)
            if (!childrenIds) throw new Error()
            if (childrenIds.length > 0) {
                showMessageBox(LS('cannotDeleteBecauseOfChildren') + "\n\n" + childrenIds.map(id => props.getVisibleGoalFromId(id)?.title ?? "id " + id).join('\n'))
            } else {
                showMessageBox(LS('deleteGoalConfirmQuestion'), true, () => {
                    const action: DeleteGoal = {type: ActionType.DELETE_GOAL, goalId: goalData.id}
                    props.dispatch(action)
                })
            }
        }

        const onPressCopyGoal = () => {
            const selectUserModeScreenProps: SelectUserModeScreenExternalProps = {
                onConfirm: mode => {
                    const createGoalScreenProps: CreateGoalScreenExternalProps = {
                        userMode: mode,
                        dataMode: {
                            type: 'copy',
                            data: goalData,
                        }
                    }
                    props.dispatch({type: ActionType.NAVIGATION_PUSH, stackItem: {
                        routeId: ScreenRouteId.CreateGoal,
                        props: createGoalScreenProps,
                    }})
                }
            }
            props.dispatch({type: ActionType.NAVIGATION_PUSH, stackItem: {
                routeId: ScreenRouteId.SelectUserMode,
                props: selectUserModeScreenProps,
            }})
        }

        const onPressConfirm = () => {
            showMessageBox(LS(privileged ? 'privilegedMakeGoalEffectiveMessage': 'nonPrivilegedMakeGoalEffectiveMessage'), true, () => {
                props.dispatch({
                    type: ActionType.CHANGE_GOAL_PHASE,
                    args: {
                        goalId: goalData.id,
                        newPhaseId: GoalPhaseId.Open,
                    }
                })
            })
        }

        const onPressUnconfirm = () => {
            showMessageBox(LS('makeGoalNotEffectiveMessage'), true, () => {
                props.dispatch({
                    type: ActionType.CHANGE_GOAL_PHASE,
                    args: {
                        goalId: goalData.id,
                        newPhaseId: GoalPhaseId.Unapproved,
                    }
                })
            })
        }

        const onPressCloseGoal = () => {
            showMessageBox(LS('closeGoalMessage'), true, () => {
                props.dispatch({
                    type: ActionType.CHANGE_GOAL_PHASE,
                    args: {
                        goalId: goalData.id,
                        newPhaseId: GoalPhaseId.Closed,
                    }
                })
            })
        }

        const onPressReopenGoal = () => {
            showMessageBox(LS('reopenGoalMessage'), true, () => {
                props.dispatch({
                    type: ActionType.CHANGE_GOAL_PHASE,
                    args: {
                        goalId: goalData.id,
                        newPhaseId: GoalPhaseId.Open,
                    }
                })
            })
        }

        const showKpiScreen = (kpiIndex: number) => {
            if (!('kpis' in goalData)) throw new Error()
            const viewKpiScreenProps: ViewKpiScreenExternalProps = {
                goalId: goalData.id,
                kpiId: goalData.kpis[kpiIndex].id,
            }
            const pushCreateKpiScreen: NavigationPush = {
                type: ActionType.NAVIGATION_PUSH,
                stackItem: {
                    routeId: ScreenRouteId.ViewKpi,
                    props: viewKpiScreenProps,
                },
            }
            props.dispatch(pushCreateKpiScreen)
        }

        const totKpiWeight = 'kpis' in goalData ? goalData.kpis.reduce((acc, kpi) => acc + (kpi.weight ?? 0), 0) : 0
        const showKpiWeights = goalData.kpiAggregationPolicyId === KpiAggregationPolicyId.Average

        const headerButtons =
            <React.Fragment>
                {(goalData.phaseId === GoalPhaseId.Unapproved && (privileged || goalData.managerMembership?.personId === props.userPerson.id))
                    && <ContentButton label={LS('confirm')} onPress={onPressConfirm} />}
                {(privileged && goalData.phaseId === GoalPhaseId.Open) && <ContentButton label={LS('cancelConfirmation')} onPress={onPressUnconfirm} />}
                {(goalData.phaseId === GoalPhaseId.Open && (privileged || goalData.managerMembership?.personId === props.userPerson.id))
                    && <ContentButton label={LS('closeGoal')} onPress={onPressCloseGoal} />}
                {(goalData.phaseId === GoalPhaseId.Closed && privileged)
                    && <ContentButton label={LS('reopen')} onPress={onPressReopenGoal} />}
                {canEdit && <ContentButton label={LS('edit')} onPress={onPressEditGoal} />}
                <ContentButton label={LS('copy')} onPress={onPressCopyGoal} />
                {canDelete && <ContentButton label={LS('delete')} onPress={onPressDeleteGoal} />}
                <ContentButton label={LS('back')} onPress={() => props.dispatch({type: ActionType.NAVIGATION_POP})} />
            </React.Fragment>
        
        const updateCollScreenProps: UpdateCollectionsScreenExternalProps = {
            goalId: 'kpis' in goalData ? goalData.id : null,
        }
        const updateKpisNavAction: NavigationPush = {
            type: ActionType.NAVIGATION_PUSH,
            stackItem: {routeId: ScreenRouteId.UpdateCollections,
                title: LS('updateKpis'),
                props: updateCollScreenProps,
            }
        }
        const canUpdateSomeKpi: boolean =
            'kpis' in goalData &&
            goalData.kpis.filter(k => userCanUpdateKpi(props.userPerson, goalData, k)).length > 0

        const updateKpisButton = canUpdateSomeKpi ?
            <ContentButton
                label={LS('updateKpis')}
                onPress={() => props.dispatch(updateKpisNavAction)}
            />
        :
            undefined

        const parent = goalData.parentId === null ? null : props.getVisibleGoalFromId(goalData.parentId) ?? 'unknown'
        const dateFormat = customSettings.showTimeOfDayForGoals ? undefined : '@d'

        return (
            <ScreenWrapper headerItem={headerButtons}>
                <View style={{flexDirection: 'column', flex: 1}}>
                    <View style={{flexDirection: 'column'}}>
                        <Card titleString={LS('goalData')}>
                            <View style={{flexDirection: 'row'}}>
                                <View style={{flexDirection: 'column', flex: 0.5}}>
                                    {goalData.isRoot && <ContentLabel string={LS('rootGoal')} />}

                                    <WrapperLabel string={LS('title')}>
                                        <ContentLabel string={goalData.title} />
                                    </WrapperLabel>

                                    {'description' in goalData &&
                                        <WrapperLabel string={LS('description')}>
                                            <ContentLabel string={goalData.description} />
                                        </WrapperLabel>}
                                    
                                    {!goalData.isRoot && <View tooltip={LS('parentTooltip')}>
                                        <ContentSeparator string={LS('parent')} rightElem={consistency?.parentship.valid === false ? <WarningSmallIcon /> : undefined} />
                                        <Table items={
                                            parent === null ?
                                                [[goalData.isRoot ? LS('noParentSoIsRoot') : LS('none')]]
                                            : parent === 'unknown' ?
                                                [[LS('unknownLabel')]]
                                            :
                                                [
                                                    [parent.title],
                                                    parent.isRoot ? [LS('rootGoal')] : [],
                                                    [dateToLocalString(parent.activePeriod.start) + ' - ' + dateToLocalString(parent.activePeriod.end)],
                                                    [goalData.managerMembership ? props.getMembershipString(goalData.managerMembership) : LS('noManager')],
                                                ].filter(r => r.length > 0)
                                        } />
                                    </View>}
                                </View>

                                <VerticalContentSeparator />

                                <View style={{flexDirection: 'column', flex: 0.5}}>
                                    {!goalData.isRoot && <>
                                        <WrapperLabel string={LS('assignees')} rightElem={(consistency?.assignment.valid === false ? <WarningSmallIcon /> : undefined)} tooltip={LS('assigneesTooltip')}>
                                            <ScrollView style={{maxHeight: 150}}>
                                                <Table items={goalData.assigneePersons.map(m => [props.getMembershipString(m)])} />
                                            </ScrollView>
                                        </WrapperLabel>

                                        <ContentSeparator />
                                    </>}

                                    <WrapperLabel string={LS('activityPeriod') + (consistency?.activePeriod.valid === false ? " ⚠️" : '')}>
                                        <View style={{flexDirection: 'row'}}>
                                            <ContentLabel string={dateToLocalString(goalData.activePeriod.start, dateFormat)} />
                                            <ContentText string={'-'} />
                                            <ContentLabel string={dateToLocalString(goalData.activePeriod.end, dateFormat)} />
                                        </View>
                                    </WrapperLabel>

                                    <View style={{flexDirection: 'row', flex: 1, alignItems: 'flex-start'}}>
                                        <WrapperLabel string={LS('credits')}>
                                            <ContentLabel string={numberToLocString(goalData.weight)} />
                                        </WrapperLabel>

                                        { customSettings.showGoalTypology &&
                                            <WrapperLabel string={LS('typology') + (consistency?.typology.valid === false ? " ⚠️" : '')}>
                                                <ContentLabel string={props.getGoalTypologyFromId(goalData.typologyId).title} />
                                            </WrapperLabel>
                                        }

                                        <WrapperLabel string={LS('visibility')}>
                                            <ContentLabel string={locVisibilityPolicy(goalData.visibilityPolicyId)} />
                                        </WrapperLabel>
                                    </View>
                                </View>
                            </View>
                        </Card>
                        {'kpis' in goalData &&
                            <Card titleString={LS('kpis')} containerStyle={{flex: 0.5}} headerItem={updateKpisButton}>
                                <WrapperLabel string={LS('aggregationMethod')} tooltip={LS('kpiAggregationTooltip')}>
                                    <ContentLabel string={locAggregationPolicy(goalData.kpiAggregationPolicyId)} />
                                </WrapperLabel>
                                <TableTyped
                                    columnNames={[LS('kpi'), LS('lastValue'), LS('date'), LS('kpiTarget')].concat(showKpiWeights ? [LS('weight'), "%"] : []).concat([""])}
                                    columnWidths={[1 as (number | string), "180px", "180px", "180px"].concat(showKpiWeights ? [0.35, 0.55] : []).concat(["45px"])}
                                    items={goalData.kpis}
                                    itemToRow={(k, i) => {
                                        const lastSample = getKpiLastSample(goalData, k)
                                        const sampleValueStr = lastSample ? getSampleValueAsString(k.sampleCollection, lastSample.value) : '-'
                                        const sampleDateStr = lastSample ? dateToLocalString(lastSample.date, '@d') : '-'
                                        return [
                                            (<ContentText string={k.title} />) as TableItemType,
                                            sampleValueStr,
                                            sampleDateStr,
                                            getKpiTargetString(k, k.sampleCollection),
                                        ].concat(showKpiWeights ? [
                                            k.weight === null ? null : numberToLocString(k.weight),
                                            totKpiWeight > 0 && k.weight !== null ? numberToLocString(k.weight / totKpiWeight * 100, 2) + " %" : "-",
                                        ] : []).concat([
                                            <View style={{flexDirection: 'row', overflow: 'hidden', justifyContent: 'flex-end'}}>
                                                <ContentButton icon={<SmallIcon name='eye' />} iconSize={1 * normalFontSize} onPress={() => showKpiScreen(i)} />
                                            </View>
                                        ])
                                    }}
                                />
                                {customSettings.showLikertProgressBar &&
                                    <WrapperLabel string={LS('status')}>
                                        <LikertProgressBar aggregateValue={calcGoalAggregateLikert(goalData)} lightBg={false} />
                                    </WrapperLabel>
                                }
                            </Card>
                        }
                    </View>
                </View>
            </ScreenWrapper>
        )
    }
}

export default connector(ViewGoalScreen)