import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import './index.css'

import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { Provider } from 'react-redux'

import { initialRootState } from './state'
import { rootReducer } from './reducers'
import { rootSaga } from './sagas'
import { ActionType } from './actions'
import * as Immer from 'immer';


Immer.enableMapSet()

export const sagaMiddleware = createSagaMiddleware()
const store = createStore(rootReducer, initialRootState, applyMiddleware(sagaMiddleware))
sagaMiddleware.run(rootSaga)

// fa partire action iniziale
store.dispatch({type: ActionType.INITIALIZE})

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("root")
)