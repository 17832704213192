import Auth0 from 'auth0-js'
import { oidc, ktaskClientUrl } from '../config'

const auth0 = new Auth0.WebAuth({
    domain: oidc.domain,
    clientID: oidc.clientId,
    redirectUri: ktaskClientUrl,
})

export const requestToken = async () => {
    auth0.authorize({
        responseType: 'id_token',
    })
}

export const tryGetTokenAndStateFromLocation = (): {token: string | null, state: string | null} => {
    const url = new URL(window.location.href)
    const hashSplit = url.hash.split('#')
    if (hashSplit.length === 2) {
        const fakeUrlStr = 'fake://fake?' + hashSplit[1]
        const fakeUrl = new URL(fakeUrlStr)

        const token = fakeUrl.searchParams.get('id_token')
        const state = fakeUrl.searchParams.get('state')
        return {token, state}
    }
    return {token: null, state: null}
}

export const logout = () => {
    auth0.logout({clientID: oidc.clientId, returnTo: window.location.origin})
}